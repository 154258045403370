.#{$namespace}lang-switch {
    display: none;
    @media #{$large-up} {
        $root: &;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        &:hover {
            #{$root}__list {
                visibility: visible;
            }
        }

        &__text {
            margin: 0;
        }

        &__icon {
            display: inline-block;
            fill: $color-dark-red-90;
            @include rem( width, 10px );
            @include rem( height, 10px );
            @include rem( margin-left, 5px );
        }

        &__top {
            display: flex;
            @include rem( padding, 10px );


        }

        &__list {
            display: block;
            visibility: hidden;
            list-style: none;
            position: absolute;
            top: 100%;
            left: 0;
            background: $color-white;
            @include rem( padding, 5px 10px );
            @include rem( width, 70px );
            margin: 0;

            &::before {
                display: block;
                content: '';
                @include rem( width, 10px );
                @include rem( height, 10px );
                position: absolute;
                top: -5px;
                background-color: $color-white;
                transform: rotate( 45deg );
                left: 15%;
                z-index: 1;
            }

            &--opened {
                display: block;
            }

            &-item {
                display: flex;
                align-items: center;
                @include rem( padding, 5px 0 );

                &-icon {
                    display: inline-block;
                    @include rem( width, 16px );
                    @include rem( height, 16px );
                }

                &-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }

                &--bordered {
                    border-bottom: 1px solid $color-light-grey;
                }

                &--first {
                    display: flex;
                    align-items: center;
                }
            }
        }

    }

}
