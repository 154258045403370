@mixin pseudo-element( $position, $position-x ) {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    background: $color-blue-10;
    @include rem( width, 3px );
    @include rem( height, 3px );
    @include rem( $position-x, -1px );
    @include rem( $position, -1px );
}

.#{$namespace}animatable-container {
    display: block;
    width: 100%;
    height: 100%;
    background: $color-white;
    $root: &;

    &__wrapper {
        display: block;
        position: relative;
        @include rem( padding, 20px );
        width: 100%;
    }


    &__group-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media #{$medium-up} {
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    &__box {
        width: calc( 100% - 8px );
        background: $color-blue-10;
        color: $color-white;
        text-align: center;
        font-size: .8rem;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        @include rem( padding, 0 4px );
        @include rem( margin, 8px );
        @include rem( height, 50px );
        @media #{$medium-up} {
            width: 100%;
        }


        &--width {

            &_33 {
                @media #{$medium-up} {
                    width: calc( 50% - 8px );
                }
                @media #{$large-up} {
                    width: calc( 33% - 8px );
                }
            }

            &_50 {
                width: calc( 70% - 8px );

                @media #{$medium-up} {
                    width: calc( 50% - 8px );
                }
            }
        }

        &--style {

            &_light {
                background: $color-blue-20;
                color: $color-blue-10;
                width: calc( 100% - 20px);
                align-self: flex-start;
                @media #{$medium-up} {
                    align-self: flex-end;
                    width: calc( 100% - 40px);
                }

                &--subbox {
                    width: calc( 100% - 40px);
                    @media #{$medium-up} {
                        width: calc( 100% - 60px);
                    }
                }

            }

            &_grey {
                background: $color-light-grey;
                color: $color-blue-10;
            }

            &_red {
                background: $color-dark-red-90;
            }
        }

        &--1 {
            @include rem( margin, 8px auto );
        }
    }

    &__group {
        display: flex;
        @include rem( padding, 40px 4px 0 );
        justify-content: space-between;
        margin: 0 auto;
        @media #{$medium-up} {
            max-width: 70%;
        }

        &--columned {
            flex-direction: column;
            @include rem( margin, 0 8px 0 0 );
            width: auto;
            max-width: 80%;
            align-items: flex-start;
            @media #{$medium-up} {
                align-items: flex-end;
                width: 100%;
                max-width: 30%;
            }
            @media #{$large-up} {
                max-width: 27%;
            }

            #{$root}__box {
                @include rem( margin, 8px 0 );
            }
        }
    }

    &__lines {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
    }

    &__line {
        background: $color-blue-10;

        &--vline {
           @include rem( width, 1px );
        }

        &--hline {
            @include rem( height, 1px );
        }

        &--1 {
            @include rem( height, 20px );
            position: absolute;
            @include rem( top, 86px );
            left: 50%;

            &::before {
                @include pseudo-element( top, left );
            }
        }

        &--2 {
            position: absolute;
            width: 22.5%;
            left: 27.5%;
            @include rem( top, 106px );

        }

        &--3 {
            position: absolute;
            width: 22.5%;
            left: 50%;
            @include rem( top, 106px );
        }

        &--4 {
            position: absolute;
            left: 27.5%;
            @include rem( height, 20px );
            width: 1px;
            @include rem( top, 106px );

            &::after {
                @include pseudo-element( bottom, left );
            }
        }

        &--5 {
            position: absolute;
            left: 72.5%;
            @include rem( height, 20px );
            @include rem( top, 106px );

            &::after {
                @include pseudo-element( bottom, left );
            }
        }

        &--6 {
            position: absolute;
            left: 72.5%;
            top: 192px;
            @include rem( height, 20px );

            &::before {
                @include pseudo-element( top, left );
            }
        }

        &--7 {
            position: absolute;
            left: 5%;
            width: 67.75%;
            top: 212px;

            @media #{$medium-up} {
                width: 80%;
            }

            @media #{$large-up} {
                left: 15%;
                top: 212px;
                width: 70%;

            }
        }

        &--8 {
            position: absolute;
            left: 5%;
            top: 212px;
            height: 860px;

            @media #{$medium-up} {
                height: 188px;
            }

            @media #{$large-up} {
                left: 15%;
            }

        }

        &--9 {
            position: absolute;
            left: 5%;
            top: 272px;
            @include rem( width, 40px );

            @media #{$medium-up} {
                @include rem( width, 20px );
            }

            @media #{$large-up} {
                left: 15%;
            }

            &::after {
                @include pseudo-element( bottom, right );
            }
        }

        &--10 {
            position: absolute;
            left: 5%;
            top: 338px;
            @include rem( width, 40px );

            @media #{$medium-up} {
                @include rem( width, 20px );
            }

            @media #{$large-up} {
                left: 15%;
            }

            &::after {
                @include pseudo-element( bottom, right );
            }
        }

        &--11 {
            position: absolute;
            left: 5%;
            top: 400px;
            @include rem( width, 40px );

            @media #{$medium-up} {
                @include rem( width, 20px );
            }

            @media #{$large-up} {
                left: 15%;
            }

            &::after {
                @include pseudo-element( bottom, right );
            }
        }
        &--12 {
            display: none;


            @media #{$medium-up} {
                position: absolute;
                top: 212px;
                @include rem( height, 20px );
                display: block;
                left: 60%;
            }

            &::after {
                @include pseudo-element( bottom, left );
            }
        }

        &--13 {
            display: none;


            @media #{$medium-up} {
                position: absolute;
                top: 212px;
                @include rem( height, 20px );
                display: block;
                left: 85%;
            }

            &::after {
                @include pseudo-element( bottom, left );
            }
        }

        &--14 {
            position: absolute;
            right: 5%;
            top: 539px;
            height: 429px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 298px;
            }

            @media #{$large-up} {
                left: 46%;
            }


            &::before {
                @include pseudo-element( top, left );
            }
        }

        &--15 {

            position: absolute;
            @include rem( height, 496px );
            right: 5%;
            top: 1108px;

            @media #{$medium-up} {
                top: 298px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::before {
                @include pseudo-element( top, left );
            }
        }

        &--16 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 578px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 328px;
            }

            @media #{$large-up} {
                left: 46%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--17 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 638px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 398px;
            }

            @media #{$large-up} {
                left: 46%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--18 {

            position: absolute;
            @include rem( width, 10px );
            right: 14%;
            top: 698px;

            @media ( min-width: 480px ) {
                right: 11%;
            }

            @media ( min-width: 590px ) {
                right: 9%;
            }

            @media #{$medium-up} {
                left: 44.5%;
                top: 458px;
            }

            @media #{$large-up} {
                left: 50%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--19 {

            position: absolute;
            @include rem( width, 10px );
            right: 14%;
            top: 768px;

            @media ( min-width: 480px ) {
                right: 11%;
            }

            @media ( min-width: 590px ) {
                right: 9%;
            }

            @media #{$medium-up} {
                left: 44.5%;
                top: 528px;
            }

            @media #{$large-up} {
                left: 50%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--20 {

            position: absolute;
            top: 678px;
            @include rem( height, 90px );
            right: 14%;

            @media ( min-width: 480px ) {
                right: 11%;
            }

            @media ( min-width: 590px ) {
                right: 9%;
            }

            @media #{$medium-up} {
                top: 438px;
                left: 44.5%;
            }
            @media #{$large-up} {
                left: 50%;
            }

            &::before {
                @include pseudo-element( top, left );
            }
        }

        &--21 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 828px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 598px;
            }

            @media #{$large-up} {
                left: 46%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--22 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 898px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 658px;
            }
            @media #{$large-up} {
                left: 46%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--23 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 968px;

            @media #{$medium-up} {
                left: 40.5%;
                top: 727px;
            }

            @media #{$large-up} {
                left: 46%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--24 {

            position: absolute;
            @include rem( width, 20px );
            right: 5%;
            top: 1138px;

            @media #{$medium-up} {
                top: 328px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--25 {

            position: absolute;
            @include rem( width, 20px );
            top: 1198px;
            right: 5%;

            @media #{$medium-up} {
                top: 398px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--26 {
            position: absolute;
            @include rem( width, 20px );
            top: 1268px;
            right: 5%;

            @media #{$medium-up} {
                top: 458px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--27 {
            position: absolute;
            @include rem( width, 20px );
            top: 1333px;
            right: 5%;

            @media #{$medium-up} {
                top: 528px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--28 {

            position: absolute;
            @include rem( width, 20px );
            top: 1398px;
            right: 5%;

            @media #{$medium-up} {
                top: 598px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--29 {

            position: absolute;
            @include rem( width, 20px );
            top: 1463px;
            right: 5%;

            @media #{$medium-up} {
                top: 658px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--30 {


            position: absolute;
            @include rem( width, 20px );
            top: 1528px;
            right: 5%;

            @media #{$medium-up} {
                top: 727px;
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--31 {

            position: absolute;
            @include rem( width, 20px );
            top: 1603px;
            right: 5%;


            @media #{$medium-up} {
                @include rem( top, 794px );
                left: 70%;
            }

            @media #{$large-up} {
                left: 73%;
            }

            &::after {
                @media #{$xsmall-only}, #{$small-only} {
                    @include pseudo-element( top, left );
                }

                @media #{$medium-up} {
                    @include pseudo-element( bottom, right );
                }
            }
        }

        &--32 {
            position: absolute;
            @include rem( top, 494px );
            @include rem( width, 40px );
            left: 5%;

            @media #{$medium-up} {
                display: none
            }

            &::after {
                @include pseudo-element( bottom, right );
            }
        }

        &--33 {
            position: absolute;
            @include rem( top, 1072px );
            @include rem( width, 40px );
            left: 5%;

            @media #{$medium-up} {
                display: none
            }

            &::after {
                @include pseudo-element( bottom, right );
            }
        }
    }

    .v-line-animation {
        animation-duration: 1.8s;
        animation-name: vline;
    }

    .h-line-animation {
        animation-duration: 1.6s;
        animation-name: hline;
    }

    .box-animation {

        @media #{$medium-up} {
            animation-duration: 1.7s;
            animation-name: box;
        }

        &-delay {
            @media #{$medium-up} {
                animation-duration: 2.7s;
                animation-name: box;
            }
        }

    }

    @keyframes vline {
        from
            {
                height: 0
            }
    }

    @keyframes hline {

        from
            {
                width: 0
            }
    }

    @keyframes box {

        from
            {
                opacity: 0
            }
    }
}
