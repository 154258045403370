.#{$namespace}hero {
    display: block;
    width: 100%;
    position: relative;
    z-index: 0;

    @media #{$large-up} {
        @include rem( margin-top, -80px );
        min-width: 100%;
    }

    &--margin {
        &_top-0 {
            margin-top: 0;

            img {
                @media #{$large-up} {
                    min-height: calc( 100vh - 80px );
                }
            }
        }
    }

    &__slider {

        &-container {
            max-width: none;
            position: relative;
            z-index: 0;
            width: 100%;
        }

        &-pagination {
            display: flex;
            justify-content: center;
            position: relative;
            @include rem( bottom, 28px );
            z-index: 10;
            @media #{$medium-up} {
                @include rem( bottom, 48px );
            }
        }

        &-arrow {
            display: none;
            @media #{$large-up} {
                display: block;
                z-index: 10;
                top: 50%;

                &--left {
                    @include rem( left, 20px );
                }

                &--right {
                    @include rem( right, 20px );
                }
                .sg-slider__arrow-icon {
                    fill: $color-white;
                }

                &:hover {
                    .sg-slider__arrow-icon {
                        fill: $color-white;
                    }
                }
            }
        }

        &-slide {
            width: 100%;

            // IE11 fix
            source {
                @media #{$medium-up} {
                    display: none;
                }
            }
        }
    }

    &__paragraph {
        margin: 0;
        font-weight: 600;
        width: 100%;
    }

    &__button {
        @include rem( margin-top, 15px );
        @media #{$medium-up} {
            @include rem( margin-top, 30px );
        }
    }

    &__background {
        position: absolute;
        display: inline;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: rgba( 13, 13, 13, .3);
    }

    &__image {
        display: block;
        @include rem( max-height, 500px );
        min-width: 100%;
        width: 100%;
        height: auto;
        position: relative;
        z-index: 0;

        @media #{$medium-up} {
            width: 100%;
        }

        @media #{$large-up} {
            width: 100%;
            height: 100%;
            max-height: none;
        }

        &--auto {
            @include rem( max-height, 300px );
            @media #{$large-up} {
                height: 100%;
                width: 100%;
            }
            @media #{$xlarge-up} {
                @include rem( max-height, 500px );
            }
        }
    }

    &__content {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media #{$medium-up} {
            width: 100%;
            @include rem( padding, 0 40px );
        }

        @media #{$large-up} {
            height: calc( 100% - 80px );
            @include rem( top, 80px );
        }

        .sg-headline {
            @media  #{$xsmall-only}, #{$small-only} {
                @include rem( font-size, 22px );
            }

            &--size_12 {
                @media #{$xsmall-only}, #{$small-only} {
                    @include responsive-font(6vw, 60px, 270px, 180px);
                }
            }
        }

        .sg-paragraph {

            &--size_6 {
                @media  #{$xsmall-only}, #{$small-only} {
                    @include rem( font-size, 22px );
                }
            }
        }

        &--align {

            &-left {
                @media  #{$xsmall-only}, #{$small-only} {
                    justify-content: flex-end;
                    align-items: flex-start;
                    text-align: left;
                    left: 0;
                    height: calc( 100% - 40px );
                    width: calc( 100% - 60px );
                    top: 0;
                    max-width: 60%;
                    margin: 0;
                }

                @media #{$medium-up} {
                    align-items: flex-start;
                    text-align: left;
                }
            }
        }

        // for content with longer headline => work.php
        &--padding {
            &_md {
                @media #{$medium-up} {
                    padding-right: 40%;
                    text-align: left;
                }
                @media #{$xlarge-up} {
                    padding-right: 20%;
                }
            }
        }

        &--video {
            width: 100%;
        }

        &--max-width {

            max-width: $base-content-width;
            margin: 0 auto;
            @media #{$medium-up} {
                align-items: flex-start;
            }

            &-slider {
                max-width: 1000px;
                width: calc( 100% - 40px );
                @media #{$xsmall-only}, #{$small-only} {
                    left: 0;
                }
                @media #{$medium-up} {
                    width: calc( 100% - 80px );
                }
                @media #{$xlarge-up} {
                    max-width: $base-content-width;
                }
            }
        }

        &--small {
            width: 80%;
            margin: 0 auto;
            @media #{$small-up} {
                width: 65%;
            }
            @media #{$medium-up} {
                width: 55%;
            }
            @media #{$large-up} {
                width: 38%;
            }
            @media #{$xlarge-up} {
                width: 35%;
            }
        }

        &-headline {
            width: 100%;
            line-height: 1;
            @media #{$large-up} {
                max-width: 80%;
            }
        }

        &-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-around;
            @media #{$medium-up} {
                @include rem( margin, 30px 0 );
            }

        }

    }
}
