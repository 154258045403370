.#{$namespace}product-form {
    display: block;
    width: 100%;
    height: auto;

    &__inner-wrapper {
        display: flex;
    }

    &__info-wrapper {
        @include rem( padding, 20px );
    }
}