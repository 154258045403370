.#{$namespace}slider-box {
    $root: &;
    display: flex;
    flex-direction: column;
    @include rem( max-width, 300px );
    @include rem( height, 420px );
    margin: 0 auto;
    @media #{$medium-up} {
        @include rem( height, 440px );
    }

    &--reversed {
        @media #{$medium-up} {
            flex-direction: column-reverse;
        }
    }

    &__wrapper{
        @include rem( padding, 20px );
        z-index: 2;
        position: relative;
    }

    &__section {
        width: auto;
        height: 50%;
        position: relative;
        background-color: $color-white;

        &-headline {
            transition: color .2s;
            @include rem( margin, 0 0 5px 0 );
        }

        &-text {
            margin: 0;
            overflow: hidden;
            transition: color .2s;

            &-weight {
                font-weight: 400;
            }
        }

        &--description {
            transition: background .2s;

            @media #{$medium-up} {
                &:hover {
                    background: $color-dark-red-80;

                    #{$root}__section-text,  #{$root}__section-headline {
                        color: $color-white;
                    }

                    .sg-bullet-list__item {

                        &::before {
                            background: $color-white;
                            border-color: $color-white;
                        }
                    }
                }
            }

            &::before {
                display: block;
                content: '';
                @include rem( width, 20px );
                @include rem( height, 20px );
                position: absolute;
                transition: background-color .2s;
                @include rem( top, -10px );
                background-color: $color-white;
                transform: rotate( 45deg );
                left: 15%;
                z-index: 1;
            }
            @media #{$medium-up} {
                &:hover:before {
                    background-color: $color-dark-red-80;
                }
            }

            &-reversed {
                @media #{$medium-up} {
                    &::before {
                        display: none;
                    }

                    &::after {
                        display: block;
                        content: '';
                        @include rem( width, 20px );
                        @include rem( height, 20px );
                        transition: background-color .2s;
                        position: absolute;
                        bottom: -10px;
                        background-color: $color-white;
                        transform: rotate( 45deg );
                        left: 15%;
                        z-index: 1;
                    }

                    &:hover:after {
                        background-color: $color-dark-red-80;
                    }
                }
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}
