.swiper {
    $root: &;

    &-pagination-bullet {
        @include rem( width, 14px );
        @include rem( height, 8px );
        @include rem( margin, 0 4px );
        background: $color-white;
        border: 0;
        transform: skew( -20deg );

        &-active {
            background: $color-dark-red-80;
        }
    }

    &-container {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &-vertical {
            #{$root}-wrapper {
                flex-direction: column;
            }
        }

        &-android {
            #{$root}-slide,
            #{$root}-wrapper {
                transform: translate3d( 0, 0, 0 );
            }
        }

        &-multirow {
            #{$root}-wrapper {
                flex-wrap: wrap;
            }
        }

        &-free-mode {
            #{$root}-wrapper {
                transition-timing-function: ease-out;
                margin: 0 auto;
            }
        }

        &-autoheight {

            #{$root}-wrapper {
                align-items: flex-start;
                transition-property: transform, height;
            }

            #{$root}-autoheight {
                #{$root}-slide {
                    height: auto;
                }
            }
        }

        #{$root}-notification {
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            opacity: 0;
            z-index: -1000;
        }
    }

    &-wp8-horizontal {
        touch-action: pan-y;
    }

    &-wp8-vertical {
        touch-action: pan-x;
    }

    &-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
    }

    &-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        max-width: none;
        transition-property: transform;
        box-sizing: content-box;
    }
}

.#{$namespace}slider {

    $root: &;
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    @media #{$medium-up} {
        max-width: 85%;
    }

    @media #{$large-up} {
        max-width: $base-content-width;
        width: 100%;
    }


    &__wrapper {
        display: flex;

    }

    &__slide {
        position: relative;
        display: block;
        width: 50%;
    }

    &__pagination {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    &__arrow {
        position: absolute;
        top: calc( 50% - 40px );
        @include rem( width, 20px );
        @include rem( height, 40px );

        &--left {
            left: 0;
            @media #{$large-up} {
                @include rem( left, -40px );
            }
        }

        &--right {
            right: 0;
            @media #{$large-up} {
                @include rem( right, -40px );
            }
        }

        &-icon {
            @include rem( width, 20px );
            @include rem( height, 40px );
            fill: $color-grey;
        }

        &:hover {
            #{$root}__arrow-icon {
                fill: $color-dark-red-90;
            }
        }
    }

}
