.#{$namespace}skew-box {
    $root: &;
    display: block;
    position: relative;
    width: auto;
    height:auto;
    transform: skew( -20deg );
    line-height: 1;

    &--width {

        &_max {

            @include rem( max-width, 150px );
            @media #{$xlarge-up} {
                @include rem( max-width, 200px );
            }
        }
    }

    &__text {
        transform: skew( 20deg );
        @include rem( padding, 4px 0 );
        font-weight: 500;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
    }

    &--mod-left {
        transform: skew( 0deg );

        #{$root}__inner-text {
            transform: skew( 0deg );
        }

        &::after {
            content: '';
            position: absolute;
            background: $color-dark-red-80;
            top: 0;
            @include rem( right, -8px );
            bottom: 0;
            @include rem( width, 16px );
            transform: skew( -20deg );
            transition: background .3s;

        }

    }

    &--black {
        background: $color-darkest;
    }

    &--red {
        background: $color-dark-red-80;
    }

    &--grey {
        background: $color-grey;
        opacity: 0.8;
    }

    &--big {
        @include rem( height, 36px );
        @include rem( line-height, 36px );
        @include rem( padding, 0 10px );
    }
}
