.#{$namespace}slider-full {
    display: block;
    height: auto;
    width: 100%;

    &__container {
        max-width: none;
        position: relative;
        height: 100%;
    }

    &__pagination {
        position: absolute;
        bottom: 5%;
        z-index: 1;
    }

    &__icon {
        fill: $color-grey;
        transform: skew(-20deg);
        @include rem( width, 20px );
        @include rem( height, 15px );
        @include rem( margin-bottom, 5px );
    }

    &__image {
        width: auto;
        height: 100%;
        display: block;
        @include rem( max-height, 650px );
        @media #{$medium-up} {
            @include rem( max-height, 550px );
        }
        @media #{$large-up} {
            width: 100%;
            max-height: none;
        }

        img {
            @media #{$large-up} {
                @include rem( min-height, 430px );
            }
        }
    }

    &__content {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            margin: 0 auto;
            width: 80%;
            left: 0;
        }

        @media #{$large-up} {
            right: 5%;
            width: 50%;
            height: auto;
            background: rgba( 255, 255, 255, .7 );
            @include rem( padding, 16px 16px 0 );
            @include rem( top, 24px );
        }

        @media #{$xlarge-up} {
            width: 40%;
            right: 8%;
            top: 10%;
        }
    }

    &__arrow {
        display: none;
        @media #{$medium-up} {
            display: block;
            z-index: 2;
        }

        &-icon {
            fill: $color-darkest;
        }

        &--left {
            left: 2%;

        }

        &--right {
            right: 2%;

        }
    }

}