.#{$namespace}boxes {
    $root: &;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    @include rem( padding, 4px );
    flex-shrink: 0;

    &--padding {

        &_0 {
            padding: 0;
        }

        &_8 {
            @include rem( padding, 8px );
        }

        &_bottom-0 {
            padding-bottom: 0;
        }

        &_bottom {
            @include rem( padding, 0 0 16px 0 );
        }
    }

    &--margin {

        &_16 {
            @include rem( margin, 16px 0 );
        }

        &_16-top {
            @include rem( margin-top, 16px );
        }

        &_16-bottom {
            @include rem( margin-bottom, 16px );
        }
    }

    &--columned {
        flex-direction: column;

        &-reversed-sm {
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                flex-direction: column-reverse;
            }

            &-only {
                @media #{$xsmall-only}, #{$small-only} {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    // for clickable buttons city-page
    &--max-width-50-lg {
        @media #{$large-up} {
            max-width: 70%;
            margin: 0 auto;
        }
        @media #{$xlarge-up} {
            max-width: 60%;
        }
    }

    &--max-width {
        max-width: $base-content-width;
        margin: 0 auto;
        @include rem( padding, 0 40px 20px );
        @media #{$large-up} {
            @include rem( padding, 0 40px 40px );
        }
    }

    &--aligned {

        &_left {
            justify-content: flex-start;
        }
    }

    //mod added to position component absolutely to image -> profile.php
    &--top {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;

    }

    &__box-wrapper {
        position: relative;
        display: block;
        @include rem( padding, 4px );
        height: auto;
        width: 100%;
        @media #{$medium-up} {
            width: 50%;
        }

        &--white {
            background: $color-white;
        }

        &--mobile-visible {
            @media #{$medium-up} {
                display: none;
            }
        }

        &--height {

            &_fixed {

                &-200 {
                    @include rem( height, 200px );
                }
            }

            &-25-sm-only {
                @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                    height: 25%
                }
            }
        }

        &--padding {

            &_0 {
                padding: 0;
            }

            &_6 {
                @include rem( padding, 6px );
            }

            &_big {
                @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                    @include rem( padding, 8px 4px );

                }
                @media #{$large-up} {
                    @include rem( padding, 8px );
                }
            }

            &_bottom-0-lg-only {
                @media #{$large-up} {
                    padding-bottom: 0;
                }
            }

            &_top-sm-only {
                @media #{$xsmall-only}, #{$small-only} {
                    @include rem( padding-top, 220px );

                }
            }

            // form right-column
            &_left-lg-only {
                padding: 0;
                @media #{$large-up} {
                    @include rem( padding-left, 8px );
                }
            }
        }

        &--width {

            &_10 {
                width: 33.333%;
                @media #{$medium-up} {
                    width: 10%;
                }

                #{$root}__icon {
                    margin: 0 auto;
                }
            }

            &_16 {

                @media #{$medium-up} {
                    width: 33.333%;
                }
                @media #{$large-up} {
                    width: 16.666%;
                }
            }

            &_20 {

                @media #{$medium-up} {
                    width: 33.333%;
                }

                @media #{$large-up} {
                    width: 20%;
                }
            }

            &_25 {
                @media #{$large-up} {
                    width: 25%;
                }
            }
            &_25-md {
                @media #{$medium-up} {
                    width: 33.333%;
                }
                @media #{$large-up} {
                    width: 25%;
                }
            }

            &_33 {
                @media #{$large-up} {
                    width: 33.333%;
                }
            }

            &_33-md {
                @media #{$medium-up} {
                    width: 33.333%;
                }
            }

            &_33-lg {
                width: 100%;
                @media #{$large-up} {
                    width: 33.333%;
                }
            }

            &_66 {
                @media #{$large-up} {
                    width: 66%;
                }
            }

            &_66-md {
                @media #{$medium-up} {
                    width: 66%;
                }
            }

            &_66-lg {
                width: 100%;
                @media #{$large-up} {
                    width: 66%;
                }
            }

            &_75 {
                @media #{$large-up} {
                    width: 75%;
                }
            }

            &_75-md {
                @media #{$medium-up} {
                    width: 66.666%;
                }
                @media #{$large-up} {
                    width: 75%;
                }
            }

            &_100 {
                width: 100%;
            }
        }

        &--bordered {

            &_right {
                @media #{$medium-up} {
                    border-right: $border;
                }
            }
        }
    }

    &__box {
        display: block;
        width: 100%;
        //height: 100%;
        height: auto;

        &--centered {
            display: flex;
            align-items: center;
        }

        &--hoverable {
            transition: transform .5s, opacity .5s;
            transform-origin: center;
            position: relative;

            &:hover {
                @media #{$large-up} {
                    transform: scale( 1.06 );
                    opacity: 1;
                    z-index: 3;
                }

            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__icon {

        display: block;

    }

}
.sprite {

    &-0 {
        @include retina-sprite($sprite-3-m-group);
    }

    &-1 {
        @include retina-sprite($sprite-audura-group);
    }

    &-2 {
        @include retina-sprite($sprite-a-c-hyd-group);
    }

    &-3 {
        @include retina-sprite($sprite-a-h-s-group);
    }

    &-4 {
        @include retina-sprite($sprite-aircraft-group);
    }

    &-5 {
        @include retina-sprite($sprite-a-l-k-o-group);
    }

    &-6 {
        @include retina-sprite($sprite-a-l-k-a-r-group);
    }

    &-7 {
        @include retina-sprite($sprite-atera-group);
    }

    &-8 {
        @include retina-sprite($sprite-alligator-group);
    }

    &-9 {
        @include retina-sprite($sprite-aral-group);
    }

    &-10 {
        @include retina-sprite($sprite-a-t-e-group);
    }

    &-11 {
        @include retina-sprite($sprite-autec-group);
    }

    &-12 {
        @include retina-sprite($sprite-autex-group);
    }

    &-13 {
        @include retina-sprite($sprite-bartec-group);
    }

    &-14 {
        @include retina-sprite($sprite-barum-group);
    }

    &-15 {
        @include retina-sprite($sprite-behr-group);
    }

    &-16 {
        @include retina-sprite($sprite-beru-group);
    }

    &-17 {
        @include retina-sprite($sprite-b-g-group);
    }

    &-18 {
        @include retina-sprite($sprite-bilstein-group);
    }

    &-19 {
        @include retina-sprite($sprite-blaupunkt-group);
    }

    &-20 {
        @include retina-sprite($sprite-bluekat-group);
    }

    &-21 {
        @include retina-sprite($sprite-blue-print-group);
    }

    &-22 {
        @include retina-sprite($sprite-bosal-group);
    }

    &-23 {
        @include retina-sprite($sprite-bosch-group);
    }

    &-24 {
        @include retina-sprite($sprite-brembo-group);
    }

    &-25 {
        @include retina-sprite($sprite-brunox-group);
    }

    &-26 {
        @include retina-sprite($sprite-bts-group);
    }

    &-27 {
        @include retina-sprite($sprite-budweg-group);
    }

    &-28 {
        @include retina-sprite($sprite-butler-group);
    }

    &-29 {
        @include retina-sprite($sprite-cartechnic-group);
    }

    &-31 {
        @include retina-sprite($sprite-castrol-group);
    }

    &-32 {
        @include retina-sprite($sprite-cemb-group);
    }

    &-33 {
        @include retina-sprite($sprite-consul-group);
    }

    &-34 {
        @include retina-sprite($sprite-continental-group);
    }

    &-35 {
        @include retina-sprite($sprite-coopers-group);
    }

    &-36 {
        @include retina-sprite($sprite-corteco-group);
    }

    &-37 {
        @include retina-sprite($sprite-cromax-group);
    }

    &-39 {
        @include retina-sprite($sprite-ctek-group);
    }

    &-38 {
        @include retina-sprite($sprite-delphi-group);
    }

    &-39 {
        @include retina-sprite($sprite-denso-group);
    }

    &-40 {
        @include retina-sprite($sprite-dresselhaus-group);
    }

    &-41 {
        @include retina-sprite($sprite-eberspacher-group);
    }

    &-42 {
        @include retina-sprite($sprite-eibach-group);
    }

    &-43 {
        @include retina-sprite($sprite-elring-group);
    }

    &-44 {
        @include retina-sprite($sprite-ernst-group);
    }

    &-45 {
        @include retina-sprite($sprite-ewo-group);
    }

    &-46 {
        @include retina-sprite($sprite-febi-group);
    }

    &-47 {
        @include retina-sprite($sprite-fein-group);
    }

    &-48 {
        @include retina-sprite($sprite-finkbeiner-group);
    }

    &-49 {
        @include retina-sprite($sprite-fuchs-group);
    }

    &-50 {
        @include retina-sprite($sprite-gedore-group);
    }

    &-51 {
        @include retina-sprite($sprite-gk-group);
    }

    &-52 {
        @include retina-sprite($sprite-g-k-n-group);
    }

    &-53 {
        @include retina-sprite($sprite-glyantin-group);
    }

    &-54 {
        @include retina-sprite($sprite-g-y-s-group);
    }

    &-55 {
        @include retina-sprite($sprite-h-r-group);
    }

    &-56 {
        @include retina-sprite($sprite-hama-group);
    }

    &-57 {
        @include retina-sprite($sprite-hazet-group);
    }

    &-58 {
        @include retina-sprite($sprite-hedson-group);
    }

    &-59 {
        @include retina-sprite($sprite-hella-group);
    }

    &-60 {
        @include retina-sprite($sprite-hella-guttmann-group);
    }

    &-61 {
        @include retina-sprite($sprite-herth-buss-group);
    }

    &-62 {
        @include retina-sprite($sprite-hirschmann-group);
    }

    &-63 {
        @include retina-sprite($sprite-h-j-s-group);
    }

    &-64 {
        @include retina-sprite($sprite-hofmann-group);
    }

    &-65 {
        @include retina-sprite($sprite-holts-group);
    }

    &-66 {
        @include retina-sprite($sprite-honeywell-group);
    }

    &-67 {
        @include retina-sprite($sprite-h-s-g-group);
    }

    &-68 {
        @include retina-sprite($sprite-huf-group);
    }

    &-69 {
        @include retina-sprite($sprite-i-s-a-m-group);
    }

    &-70 {
        @include retina-sprite($sprite-jaeger-group);
    }

    &-71 {
        @include retina-sprite($sprite-john-beam-group);
    }

    &-72 {
        @include retina-sprite($sprite-kaeser-group);
    }

    &-73 {
        @include retina-sprite($sprite-karcher-group);
    }

    &-74 {
        @include retina-sprite($sprite-knipex-group);
    }

    &-75 {
        @include retina-sprite($sprite-koch-group);
    }

    &-76 {
        @include retina-sprite($sprite-koni-group);
    }

    &-77 {
        @include retina-sprite($sprite-kronprinz-group);
    }

    &-78 {
        @include retina-sprite($sprite-k-stool-group);
    }

    &-79 {
        @include retina-sprite($sprite-kunzer-group);
    }

    &-80 {
        @include retina-sprite($sprite-k-w-group);
    }

    &-81 {
        @include retina-sprite($sprite-k-y-b-group);
    }

    &-82 {
        @include retina-sprite($sprite-l-emfirder-group);
    }

    &-83 {
        @include retina-sprite($sprite-lesjifors-group);
    }

    &-84 {
        @include retina-sprite($sprite-libro-group);
    }

    &-85 {
        @include retina-sprite($sprite-liqui-moly-group);
    }

    &-86 {
        @include retina-sprite($sprite-loctite-group);
    }

    &-87 {
        @include retina-sprite($sprite-l-r-group);
    }

    &-88 {
        @include retina-sprite($sprite-l-r-t-group);
    }

    &-89 {
        @include retina-sprite($sprite-magma-group);
    }

    &-90 {
        @include retina-sprite($sprite-magnet-marelli-group);
    }

    &-91 {
        @include retina-sprite($sprite-mahle-group);
    }

    &-92 {
        @include retina-sprite($sprite-mekra-group);
    }

    &-93 {
        @include retina-sprite($sprite-metabo-group);
    }

    &-94 {
        @include retina-sprite($sprite-meyer-motoren-group);
    }

    &-95 {
        @include retina-sprite($sprite-meyle-group);
    }

    &-96 {
        @include retina-sprite($sprite-mobil-group);
    }

    &-97 {
        @include retina-sprite($sprite-moll-group);
    }

    &-98 {
        @include retina-sprite($sprite-motip-dupli-group);
    }

    &-99 {
        @include retina-sprite($sprite-n-g-k-group);
    }

    &-100 {
        @include retina-sprite($sprite-nilfisk-group);
    }

    &-101 {
        @include retina-sprite($sprite-nissens-group);
    }

    &-102 {
        @include retina-sprite($sprite-nrf-group);
    }

    &-103 {
        @include retina-sprite($sprite-n-t-n-group);
    }

    &-104 {
        @include retina-sprite($sprite-nussbaum-group);
    }

    &-105 {
        @include retina-sprite($sprite-oberland-group);
    }

    &-106 {
        @include retina-sprite($sprite-osram-group);
    }

    &-107 {
        @include retina-sprite($sprite-ottokind-group);
    }

    &-108 {
        @include retina-sprite($sprite-pedol-group);
    }

    &-109 {
        @include retina-sprite($sprite-perfect-group);
    }

    &-110 {
        @include retina-sprite($sprite-petec-group);
    }

    &-111 {
        @include retina-sprite($sprite-pferd-group);
    }

    &-112 {
        @include retina-sprite($sprite-philips-group);
    }

    &-113 {
        @include retina-sprite($sprite-pierpurg-group);
    }

    &-114 {
        @include retina-sprite($sprite-pikington-group);
    }

    &-115 {
        @include retina-sprite($sprite-pioneer-group);
    }

    &-116 {
        @include retina-sprite($sprite-pneutec-group);
    }

    &-117 {
        @include retina-sprite($sprite-prasco-group);
    }

    &-118 {
        @include retina-sprite($sprite-prema-group);
    }

    &-119 {
        @include retina-sprite($sprite-premium-group);
    }

    &-120 {
        @include retina-sprite($sprite-quick-brake-group);
    }

    &-121 {
        @include retina-sprite($sprite-rapid-group);
    }

    &-122 {
        @include retina-sprite($sprite-rauscher-group);
    }

    &-123 {
        @include retina-sprite($sprite-robinair-group);
    }

    &-124 {
        @include retina-sprite($sprite-rocket-group);
    }

    &-125 {
        @include retina-sprite($sprite-rohrlux-group);
    }

    &-126 {
        @include retina-sprite($sprite-sachs-group);
    }

    &-127 {
        @include retina-sprite($sprite-saint-gobain-group);
    }

    &-128 {
        @include retina-sprite($sprite-sata-group);
    }

    &-129 {
        @include retina-sprite($sprite-schaeffler-group);
    }

    &-130 {
        @include retina-sprite($sprite-schlatter-group);
    }

    &-131 {
        @include retina-sprite($sprite-schrader-group);
    }

    &-132 {
        @include retina-sprite($sprite-schweikraft-group);
    }

    &-133 {
        @include retina-sprite($sprite-sitek-group);
    }

    &-134 {
        @include retina-sprite($sprite-s-k-f-group);
    }
    &-135 {
        @include retina-sprite($sprite-slift-group);
    }
    &-136 {
        @include retina-sprite($sprite-sonax-group);
    }
    &-137 {
        @include retina-sprite($sprite-stabilus-group);
    }
    &-138 {
        @include retina-sprite($sprite-stec-group);
    }

    &-139 {
        @include retina-sprite($sprite-stierius-group);
    }

    &-140 {
        @include retina-sprite($sprite-supersprint-group);
    }

    &-141 {
        @include retina-sprite($sprite-s-w-f-group);
    }

    &-142 {
        @include retina-sprite($sprite-tecalemit-group);
    }

    &-143 {
        @include retina-sprite($sprite-teroson-group);
    }

    &-144 {
        @include retina-sprite($sprite-tenneco-group);
    }
    &-145 {
        @include retina-sprite($sprite-t-r-w-group);
    }

    &-146 {
        @include retina-sprite($sprite-tunap-group);
    }

    &-147 {
        @include retina-sprite($sprite-twintec-group);
    }

    &-148 {
        @include retina-sprite($sprite-t-y-c-group);
    }

    &-149 {
        @include retina-sprite($sprite-uebler-group);
    }

    &-150 {
        @include retina-sprite($sprite-u-l-o-group);
    }

    &-151 {
        @include retina-sprite($sprite-vaico-group);
    }

    &-152 {
        @include retina-sprite($sprite-valeo-group);
    }

    &-153 {
        @include retina-sprite($sprite-van-wezel-group);
    }

    &-154 {
        @include retina-sprite($sprite-varta-group);
    }

    &-155 {
        @include retina-sprite($sprite-v-d-o-group);
    }

    &-156 {
        @include retina-sprite($sprite-vemo-group);
    }

    &-157 {
        @include retina-sprite($sprite-vigor-group);
    }

    &-158 {
        @include retina-sprite($sprite-waeco-group);
    }

    &-159 {
        @include retina-sprite($sprite-wahler-group);
    }

    &-160 {
        @include retina-sprite($sprite-webasto-group);
    }

    &-161 {
        @include retina-sprite($sprite-westfalia-group);
    }

    &-162 {
        @include retina-sprite($sprite-wynns-group);
    }
    &-163 {
        @include retina-sprite($sprite-z-f-group);
    }
}























