.#{$namespace}linked-image {
    $root: &;
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &--height {

        &_fixed {
            @include rem( height, 270px );
            @media #{$large-up} {
                @include rem( height, 360px );
            }

            &-md {
                @include rem( height, 270px );
            }
        }

    }

    &--hoverable {

        &:hover {
            #{$root}__hidden-section {
                visibility: visible;
                opacity: 1;
            }

            #{$root}__hidden-background {
                opacity: .6;
            }

            #{$root}__link {
                display: none;
            }
        }
    }

    &__image {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__link {
        position: absolute;
        transition: background .3s, width .1s;
        @include rem( bottom, 30px );
        @include rem( padding, 0 15px );
        left: 0;
        height: auto;
        max-width: calc( 100% - 24px );

        &:hover {
            background: $color-dark-red-90;
        }

        &:hover::after {
            background: $color-dark-red-90;
        }

        &--inline {
            display: inline-block;
            @include rem( padding, 0 10px 0 5px );
            @include rem( height, 30px );
            @include rem( margin-left, 5px );
            position: relative;
            bottom: 0;
            vertical-align: bottom;
            @media #{$medium-up} {
                @include rem( margin-bottom, 5px );
            }
        }

        &--width {
            &_max-80 {
                max-width: 80%;
            }
        }

        &--transparent {
            background: rgba( 255, 255, 255, .6 );
            border: 0;
            cursor: default;

            &:after {
                display: none;
            }

            &:hover {
                background: rgba( 255, 255, 255, .6 );

                #{$root}__link-text {
                    color: $color-darkest;
                }
            }

            &:hover::after {
                background: rgba( 255, 255, 255, .6 );
            }

        }
    }


    &__triangle-transparent {
        position: absolute;
        top: 0;
        @include rem(right, -35px);
        bottom: 0;
        width: 0;
        height: 100%;
        border-style: solid;
        background: transparent;
        border-width: 0;
        border-color: rgba(255, 255, 255, .6) transparent transparent transparent;
    }

    &__link-text {
        text-transform: initial;
        line-height: 1.65;
        @include rem( padding, 4px 0 );

    }

    &__headline-wrapper {
        display: block;
        position: absolute;
        z-index: 10;
        width: 100%;
        @include rem( padding, 0 0 15px 15px );
        bottom: 0;
        @media #{$medium-up} {
            @include rem( padding, 0 0 30px 30px );
        }

    }

    &__headline {
        line-height: 1.6;
        @media #{$medium-up} {
            line-height: 1;
        }
    }

    &__subheadline {
        margin: 0;
    }

    &__hidden {

        &-section {
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            padding: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            visibility: hidden;
            opacity: 0;
            transition: visibility .3s, height .3s, opacity .3s;
        }

        &-description {
            z-index: 2;
            position: relative;
            @include rem( padding, 30px );
            display: block;
            text-align: left;
            height: 100%;
        }

        &-background {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: $color-darkest;
            opacity: 0;
            transition: opacity .3s, height .3s ease;
        }

        &-link {
            position: absolute;
            @include rem(bottom, 30px);
        }
    }

}