.#{$namespace}contact-box {
    position: relative;
    background: $color-white;
    width: 100%;
    height: auto;
    @media #{$medium-up} {
        height: 100%;
        @include rem( height, 500px );
    }
    @media #{$large-up} {
        @include rem( max-height, 550px );
    }

    &--small {
        @media #{$medium-up} {
            height: 100%;
            @include rem( height, 380px );
        }
    }

    &__icon-arrow {
        @include rem( width, 10px );
        @include rem( height, 10px );
        display: inline;
        fill: $color-dark-red-80;
        transition: fill .2s;

        &:hover {
          fill: $color-dark-red-90;
        }
    }

    &__text {
        margin: 0;
    }

    &__section {
        height: 50%;
        position: relative;
        @media #{$large-up} {
            height: 45%;
        }

        &--height {
            &_60 {
                height: 60%;
            }
        }

        &--info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 50%;
            @include rem(padding, 15px);
            @media #{$large-up} {
                height: 55%;

            }
        }

    }
    &__info-wrapper {
        display: block;
        width: 100%;
        height: auto;
        @include rem( padding, 15px 0 30px );

        &--bottom {
            @include rem( padding, 15px 0 0 );
            border-top: $border;
        }

    }

    //&__image {
    //    display: block;
    //    width: 100%;
    //    height: 100%;
    //}

    //&__button {
    //    position: absolute;
    //    transition: background .3s;
    //    @include rem( bottom, 30px );
    //    @include rem( padding, 0 15px );
    //    left: 0;
    //
    //
    //    &:hover {
    //        background: $color-dark-red-90;
    //    }
    //
    //    &:hover::after {
    //        background: $color-dark-red-90;
    //    }
    //}

    &__icon {
        @include rem( width, 20px );
        @include rem( height, 20px );
    }

    &__contact {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include rem( padding, 5px 0 );
    }

    &__paragraph {
        margin: 0;
        @include rem( padding-left, 15px );

    }
}
