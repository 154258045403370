.#{$namespace}barcode-generator {
    width: 100%;

    &__form {
        width: 100%;
        display: block;

        &--hidden {
            display: none;
        }

        form {
            flex: 0 0 100%;
            flex-wrap: wrap;

        }
    }

    &__download {
        position: relative;
        display: block;
        width: 100%;


        &--hidden {
            display: none;
        }
    }

    &__message {
        padding: 10px 0;
        color: $red;
    }
}