.#{$namespace}history {
    @include rem(height, 650px);
    width: 100%;
}

.tl {
    $root: &;

    &-timeline {
        font-family: "Roboto", sans-serif  !important;
    }

    &-text {
        font-family: "Roboto", sans-serif  !important;
        display: block !important;

        h2, &-headline-title {
            font-family: "Roboto", sans-serif  !important;
            font-weight: 400;
            color: $color-darkest;
            font-size: 1.5rem !important;
            line-height: 1.2;
            @media #{$medium-up} {
                @include rem( line-height, 36px !important);
                font-size:  2rem !important;
            }
        }


        p {
            font-family: "Roboto", sans-serif  !important;
            font-weight: 300  !important;
            color: $color-grey  !important;
        }
    }

    &-timemarker-text, &-timemarker {
        font-family: "Roboto", sans-serif  !important;
        font-weight: 300  !important;
        color: $color-grey  !important;
    }

    &-attribution {
        display: none  !important;
    }

    &-timenav {
        @media #{$xsmall-only}, #{$small-only} {
            display: none !important;
        }
        background: $color-light-grey  !important;
    }

    &-headline-date {

        font-family: "Roboto", sans-serif !important;
        @include rem( margin-bottom, 15px !important);
        color: $color-darkest !important;
        font-size: 2rem !important;
        font-weight: 200 !important;
        line-height: 1 !important;

        @media #{$medium-up} {
            font-size: 4rem !important;
        }

        &::after {
            content: ''  !important;
            background: $color-dark-red-90  !important;
            display: block  !important;
            @include rem( width, 60px  !important);
            @include rem( height, 1px  !important);
        }
    }

    &-timemarker-active {
        border: 1px solid $color-dark-red-90 !important;
    }

    &-slide-scrollable-container {
        margin: 0 auto !important;
    }

    &-slide-content {
        display: flex !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include rem( max-width, $base-content-width !important );

        @media #{$xsmall-only}, #{$small-only} {
            flex-direction: column !important;
            @include rem( padding-bottom, 30px  !important );
        }

        @media #{$medium-only} {
            @include rem( padding, 30px 40px !important );
            width: calc( 100% - 80px ) !important;
        }

        @media #{$medium-up} {
            flex-direction: row-reverse !important;
        }

        @media #{$large-up} {
            @include rem( padding, 0 20px !important );
            width: calc( 100% - 40px ) !important;
        }

        #{$root}-text {
            text-align: left !important;
            @media #{$medium-only} {
                @include rem( padding, 0 50px !important );
            }
        }

        &-container {
            @include rem( max-width, $base-content-width !important );
        }
    }

    &-media-content {
        @media #{$large-up} {
            @include rem( padding, 0 30px 30px !important);
        }
    }

    &-media {
        display: block !important;
        @media #{$xsmall-only}, #{$small-only} {
            border-top: 0 !important;
        }

        @media #{$medium-only} {
            display: none !important;
        }

        @media #{$large-up} {
            width: 50% !important;
        }
    }

    &-media-image {
        display: block;
        width: 100%;
        height: 100%;
        @include rem( max-width, 500px !important );
    }

    &-text {
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            width: 100% !important;
            max-width: none !important;
        }

    }

    &-storyslider {
        @include rem( min-height, 500px !important);
        @media #{$xlarge-up} {
            @include rem( min-height, 620px !important);
            @include rem( padding, 0 40px !important);
            width: calc( 100% - 80px );
        }
    }

    &-slidenav-content-container {
        &:hover {
            #{$root}-slidenav-icon {
                background: $color-dark-red-90 !important;

                &::after {
                    background: $color-dark-red-90 !important;
                }
            }
        }
    }

    &-title, &-description {
        transition: color .1s !important;
    }

    &-slidenav-icon {
        background: $color-grey !important;
        transform: rotate( -45deg ) !important;
        @include rem( height, 20px  !important);
        display: block !important;
        position: relative !important;
        @include rem( width, 1px  !important);
        @include rem( top, -10px  !important);
        transition: background .1s !important;

        &::after {
            background: $color-grey!important;
            content: ''!important;
            @include rem( height, 1px !important);
            @include rem( width, 20px !important);
            @include rem( left, -20px !important);
            @include rem( top, 19px !important);
            position: absolute !important;
            transition: background .2s !important;
        }

        &::before {
            display: none !important;
        }
    }

    &-slidenav-previous {
        #{$root}-slidenav-icon {
            transform: rotate( 45deg )  !important;
            @include rem( left, 10px !important);
            @include rem( top, -10px !important);

            &::after {
                @include rem( left, 1px !important);
            }
        }
    }
}


