.#{$namespace}slider-boxes {
    position: relative;
    width: 100%;

    &__slide {
        width: 100%;
        @include rem( min-width, 300px );

        @media #{$large-up} {
            min-width: auto;
            width: 25%;
        }

    }

    &__pagination {
        @include rem( padding-top, 20px );
        @media #{$large-up} {
            @include rem( padding-top, 40px );

        }
    }

    &__container {
        max-width: none;

        &-arrow {
            display: none;
            @media #{$large-up} {
                display: block;
            }
        }
    }
}
