.#{$namespace}slider-gallery {
    position: relative;
    width: 100%;
    @media #{$medium-up} {
        @include rem( margin-bottom, 40px );
    }

    &__pagination {
        @include rem( margin-top, 20px );
        @media #{$medium-up} {
            display: none;
        }
    }

    &__arrow {
        display: none;
        @media #{$medium-up} {
            display: block;
            top: calc( 50% - 15px );
        }

    }

    &__slide {
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            @include rem( min-width, 380px );

            .sg-gallery-layout {
                &__item {
                    &--hero {
                        display: block;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity .3s;
}
