.#{$namespace}city-box {
    $root: &;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;

    &__wrapper {

        &:hover {

            #{$root}__inner-text--hidden {
                visibility: visible;
                opacity: 1;
                height: 100%;
            }

            #{$root}__wrapper-background {
                opacity: .7;
            }

            #{$root}__description {
                top: 5%;
                @media #{$medium-up} {
                    top: 15%;
                    height: 100%;
                }
                @media #{$large-only} {
                    top: 5%;
                }
            }

        }
    }

    &__icon {
        background: $color-dark-red-80;
        @include rem( height, 20px );
        @include rem( width, 125px );
        display: block;
        position: absolute;
        transform: rotate(-30deg);
        @include rem( top, 13px );
        @include rem( left, -25px );
        bottom: 0;
        z-index: 4;
        text-align: center;

        &-wrapper {
            display: block;
            position: absolute;
            @include rem( height, 56px );
            @include rem( width, 96px );
            @include rem( top, -5px );
            @include rem( left, -5px );
            overflow: hidden;

            &::before {
                background: $color-dark-red-90;
                content: '';
                @include rem( width, 20px );
                @include rem( height, 10px );
                position: absolute;
                z-index: -1;
                display: block;
                right: 0;
                top: 0;
            }

            &::after {
                background: $color-dark-red-90;
                content: '';
                @include rem( width, 10px );
                @include rem( height, 20px );
                position: absolute;
                z-index: -1;
                display: block;
                left: 0;
                bottom: 0;
            }
        }

        &-text {
            display: block;
            margin: 0;
            text-transform: uppercase;
            @include rem( line-height, 20px );
            font-size: .7rem;
            font-weight: 500;
        }
    }


    &__wrapper-background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $color-darkest;
        opacity: .3;
        z-index: 2;
        transition: opacity .3s;
    }

    &__image-wrapper {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
        @include rem( max-height, 184px );
        object-fit: cover;
        @media #{$medium-up} {
            height: 100%;
            max-height: none;
        }

    }

    &__description {
        position: absolute;
        z-index: 3;
        right: 0;
        width: 100%;
        @include rem( padding, 15px );
        top: 60%;
        bottom: 0;
        transition: top .3s;
        @media #{$xlarge-up} {
            left: 5%;
            @include rem( padding, 25px );
        }

    }

    &__headline {
        margin: 0;
        text-align: left;
        font-weight: 500;
    }

    &__inner-text {
        display: block;
        font-weight: 400;
        @include rem( padding-top, 15px );

        &-p {
            margin: 0;
        }

        &--hidden {
            visibility: hidden;
            opacity: 0;
            height: 0;
            transition: visibility .5s cubic-bezier( 0, 1.38, .97, 1.37 ), height .5s cubic-bezier( 0, 1.38, .97, 1.37 ), opacity .3s;
        }
    }
}
