.#{$namespace}store-locator {
    display: block;
    width: 100%;
    height: 100%;

    &__logo-wrapper {
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        @include rem( padding, 0 16px );
        background: rgba( 255, 255, 255, .7 );

        @media #{$medium-up} {
            position: absolute;
            z-index: 10;
            top: 0;
            right: 5%;
            display: block;
        }
    }

    &__logo {
        @include rem( max-width, 180px );
        margin: 0 0 0 auto;

        @media #{$medium-up} {
            @include rem( max-width, 240px );
            @include rem( margin-top, 5px );
        }

        @media #{$large-up} {
            margin-top: 0;
        }
    }

    &__container {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__map-container {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    &__map {
        display: block;
        width: 100%;
        @include rem( height, 450px );
        @media #{$medium-up} {
            height: 100vh;
        }
    }

    &__form {
        display: block;
        width: 100%;
        z-index: 100;

        @media #{$medium-up} {
            position: absolute;
            top: 5%;
            left: 5%;
            @include rem( max-width, 280px );
        }
    }

    &__list-container {
        display: block;
        box-shadow: 0 1px 4px 0 rgba( 34, 34, 34, .31 );

        @media #{$medium-up} {
            position: absolute;
            top: 10%;
            right: 5%;
        }
    }

    &__list {
        display: block;
        padding: 0;
        width: 100%;
        @include rem( max-height, 500px );
        height: 100%;
        overflow-y: scroll;

        @media #{$xlarge-up} {
            @include rem( max-height, 650px );
        }

        &-item {
            @include rem( padding, 16px );
            
            &:nth-child(odd) {
                background: $color-light-grey;
            }
        }
    }

    &__location {
        @include rem( padding, 8px 0 8px 4px );
        @include rem( max-width, 250px );

        &-wrapper {
            display: block;
            @include rem( padding-left, 20px );
        }

        &-text {
            display: block;
            @include rem( margin-top, 8px );
        }
    }

    &__icon {
        display: inline-block;
        fill: $color-dark-grey;
        @include rem( width, 13px );
        @include rem( height, 13px );
        @include rem( margin-left, -17px );
    }
}

/* storeLocator */

$white: $color-white;
$gray: $color-grey;
$darkgray: $color-dark-grey;
$titlegray: $color-grey;
$shadow: $color-light-grey;
$textgray: $color-dark-grey;
$darkergray: $color-darkest;
$black: $color-darkest;
$blue: #005293;
$red: $color-dark-red-80;
$baseFont: $base-font-family;
$tablet: '(min-width: 768px)';
$tabletLarge: '(min-width: 1024px)';


/* Infowindow Roboto font override */
.gm-style {
    div, span, label, a {
        font-family: $baseFont;
    }
}

/* InfoBubble font size */
.bh-sl-window {
    font-size: 1rem;
}

.bh-sl-error {
    display: block;
    color: $red;
    font-weight: bold;
    @include rem( padding, 8px 0 );
    width: 100%;
}

.bh-sl-container {

    .bh-sl-filters-container {
        @include rem( margin, 16px 0 );
        width: 100%;

        .bh-sl-filters {
            list-style: none;
            margin: 0 100px 0 0;
            padding: 0;

            li {
                display: block;
                @include rem( margin, 8px 0 );
                width: 100%;
            }
        }
    }

    .bh-sl-loc-list {

        .list-label {
            background: darken( $blue, 20% );
            border-radius: 16px;
            color: $white;
            display: block;
            float: left;
            font-weight: bold;
            @include rem( padding, 4px 8px );
            @include rem( margin, 8px 0 0 16px );
            text-align: center;
            width: auto;
            min-width: 1rem;
        }

        .list-details {
            float: left;
            margin-left: 6px;
            width: 80%;

            .list-content {
                padding: 10px;
            }

            .loc-dist {
                color: $darkgray;
                font-weight: bold;
                font-style: italic;
            }
        }

        .list-focus {
            border: 1px solid $color-blue;
            transition: border .2s linear 0s, box-shadow .2s linear 0s;
        }

        .bh-sl-close-directions-container {
            height: 20px;
            position: relative;
            width: 100%;

            .bh-sl-close-icon {
                @include rem( right, 8px );
                top: 0;
            }
        }

        .bh-sl-directions-panel {
            margin: 0 2%;

            /* Avoid issues with table-layout */
            table {
                table-layout: auto;
                width: 100%;
            }

            table, td {
                vertical-align: middle;
                border-collapse: separate;
            }

            td {
                padding: 1px;
            }

            .adp-placemark {
                @include rem( margin, 8px 0 );
                border: $border;
            }

            .adp-marker {
                @include rem( padding, 4px );

            }
        }

        .bh-sl-noresults-title {
            font-weight: bold;
            @include rem( margin, 16px );
        }

        .bh-sl-noresults-desc {
            @include rem( margin, 0 16px );
        }
    }

    .loc-name {
        /* Picked up by both list and infowindows */
        font-size: 1rem;
        font-weight: bold;
        @include rem( padding-bottom, 16px );

        &--color {
            &_red {
                color: $color-dark-red-80;
            }
        }

        &--padding {
            &_0 {
                padding-bottom: 0;
            }
        }
    }

    .loc-addr3 {
        @include rem( padding-bottom, 8px );
        @include rem( margin-bottom, 8px );
        border-bottom: $border;
    }

    .bh-sl-pagination-container {
        clear: both;

        ol {
            list-style-type: none;
            margin: 0;
            @include rem( padding, 8px 0 );
            text-align: center;

            li {
                color: $blue;
                cursor: pointer;
                display: inline-block;
                font: bold 1rem $baseFont;
                @include rem( padding, 8px );
            }

            .bh-sl-current {
                color: $textgray;
                cursor: auto;
                text-decoration: none;
            }
        }
    }
}

.bh-sl-close-icon {
    @include rem( height, 24px );
    @include rem( width, 24px );
    cursor: pointer;
    position: absolute;

    &:after,
    &:before {
        background: $gray;
        content: '';
        display: block;
        @include rem( height, 24px );
        margin: -3px 0 0 -1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        @include rem( top, 4px );
        @include rem( right, 4px );
        @include rem( width, 4px );
        transform: rotate( 45deg );
    }

    &:hover:after,
    &:hover:before {
        background: darken( $gray, 10% );
    }

    &:before {
        transform: rotate( -45deg );
    }
}