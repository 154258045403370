.#{$namespace}member {
    $root: &;
    background: $color-white;
    width: 100%;
    height: 100%;
    transition: background .2s;

    &__wrapper {
        @include rem( padding, 30px );
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--row {
        flex-direction: row;
    }
    // for ausbildung page
    &--height {

        &_fixed-100 {
            @include rem( height, 120px );

        }
    }

    &--hoverable {
        cursor: pointer;
        &:hover {

            background: $color-dark-red-80;
            #{$root}__headline, #{$root}__description {
                color: $color-white;
            }
        }
    }

    &--padding {

        &_15 {
            @include rem( padding, 0 15px );
        }
    }

    &__wrapper{

        &-description {
            display: block;
            text-align: center;

            &--aligned-left {
                text-align: left;
            }
        }

        &-image {
            width: 40%;
            height: 100%;
            @media #{$large-up} {
                width: 60%;
            }

            &--padding_30 {
                @include rem( padding, 0 15px );
                @media #{$large-up} {
                    @include rem( padding, 0 30px );
                }
            }

            &--padding_15 {
                @include rem( padding-bottom, 15px );
            }

            &--aligned {
                &_bottom {
                    align-self: flex-end;
                    height: calc( 100% + 8px );
                    width: 45%;

                    @media #{$medium-up} {
                        width: 45%;
                    }
                    @media #{$large-up} {
                        width: 35%;
                    }
                }
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__headline {
        transition: color .2s;
        font-weight: 500;
        margin: 0;
    }

    &__description {
        transition: color .2s;
        text-transform: uppercase;
        margin: 0;
    }
}