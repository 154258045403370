.#{$namespace}job-list {
    display: block;
    width: 100%;
    height: auto;
    background: $color-white;
    @include rem( margin-top, 16px );

    &__wrapper {
        @include rem( padding, 0 20px 20px );
    }

    &__list {
        flex-direction: column;
        padding: 0;
    }

    &__item {
        display: flex;
        flex-direction: row;
        color: $color-darkest;
        font-weight: 400;
        font-size:  1.071rem;
        margin: 0;
        width: 100%;
        padding: 0;
        @include rem( min-height, 40px );
        text-transform: none;

        &-name {
            @include rem( padding, 10px );
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media #{$medium-up} {
                width: 55%;
                border-right: 1px solid $color-white;
            }
        }

        &-city {
            @include rem( padding, 10px );
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @media #{$medium-up} {
                width: 25%;
                border-right: 2px solid $color-white;
            }
        }

        &-social {
            visibility: hidden;
            width: 0;
            height: 0;
            padding: 0;
            margin: 0;
            @media #{$medium-up} {
                display: flex;
                visibility: visible;
                height: auto;
                @include rem( padding, 10px );
                width: 20%;
            }
        }

        &--trigger {
            position: relative;
            z-index: 1;
        }

        &:nth-child(odd) {
            background: $color-light;
        }

        &--header {
            background: $color-white;
            &:nth-child(odd) {
                background: $color-white;
            }
        }
    }

    &__header-item {
        display: flex;
        text-transform: uppercase;
        @include rem( font-size, 12px );
        @include rem( padding, 20px 10px );

        &--position {
            cursor: pointer;
            padding-left: 0;
        }

        &--city {
            cursor: pointer;
        }

    }

    &__paragraph {
        margin: 0;
    }

    &__icon {
        display: block;


        &--sm {
            @include rem( width, 10px );
            @include rem( height, 12px );
            @include rem( margin-left, 8px );
            fill: $color-dark-red-80;
        }

        &--md {
            @include rem( width, 15px );
            @include rem( height, 15px );
            @include rem( margin-left, 8px );
        }

        &--lg {
            @include rem( width, 20px );
            @include rem( height, 20px );
            @include rem( margin-right, 8px );
            fill: $color-dark-red-80;
        }
    }
}
