.#{$namespace}job-form {
    display: block;
    width: 100%;
    height: auto;


    @media #{$medium-up} {
        @include rem( height, 70px );
    }

    &__container {
        @include rem( box-shadow, 0 2px 4px 0 rgba( 0, 0, 0, .11 ) );
        background: $color-white;
        @include rem( padding, 0 10px );
        display: flex;
        border: 0;
        margin: 0;
        width: 100%;
        flex-wrap: wrap;
    }

    &__input {
        display: block;
        position: relative;
        padding: 0;

        &--type {

            &_text {
                width: 100%;
                @media #{$medium-up} {
                    width: 55%;
                }

            }

            &_select {
                width: 100%;
                @media #{$medium-up} {
                    width: 45%;
                }

                .sg-html-select {
                    width: 100%;

                    &__trigger {
                        height: auto;
                        @include rem( padding, 30px 10px 10px 10px );
                        border: 0;

                        &-caret {
                            background: transparent;

                            &::before {
                                background: transparent;
                            }
                        }

                        &-text {
                            color: $color-darkest;
                            @include rem( font-size, 16px );
                        }


                    }

                    &__menu {

                        &-link {
                            @include rem( padding, 15px 10px );
                        }

                        &-item-text {
                            color: $color-darkest;
                        }
                    }
                }



            }
        }

        &-field {
            @include rem(padding, 30px 10px 10px 10px);
            color: $color-darkest;
            width: 100%;

            &--type {

                &_text {
                    border: 0;
                    @media #{$medium-up} {
                        border-right: $border;
                    }

                    &::-webkit-input-placeholder {
                        color: $color-darkest;
                        @include rem( font-size, 16px );
                    }
                }
            }
        }

    }

    &__label {
        position: absolute;
        @include rem( top, 10px );
        @include rem( left, 10px );
        z-index: 10;
        width: 100%;
        text-transform: uppercase;
        color: $color-grey;
        @include rem( font-size, 12px );
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        @include rem( padding, 0 10px );

        @media #{$medium-up} {
            width: 15%;
        }
    }

    &__button {
        margin: 0;
    }
}
