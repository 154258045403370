.#{$namespace}breadcrumb {
    display: none;
    @media #{$medium-up} {
        display: flex;

        &--top {
            position: absolute;
            top: 5%;
            @include rem( left, 55px );

        }

        &__item {
            display: flex;
            margin: 0;
            width: auto;
            text-transform: capitalize;
            text-decoration: none;
            font-weight: 200;
            @include rem( padding, 0 6px );

            &--not-last {

                &::after {
                    content: '';
                    background: $color-dark-red-80;
                    transform: rotate( 20deg );
                    display: block;
                    height: 1rem;
                    @include rem( width, 1px );
                    margin: 0;
                    @include rem( margin-left, 12px );
                    position: relative;
                    right: 0;
                }
            }
        }
    }

}
