.#{$namespace}article {
    $root: &;
    display: block;
    background: $color-white;
    width: 100%;
    //height: 100%;
    height: auto;
    @include rem( padding, 30px );
    @include rem( margin, 8px 0 );
    position: relative;

    &--margin {

        &_0 {
            margin: 0;
        }

        &_top-0 {
            margin-top: 0;
        }

        &_top-only {
            @include rem( margin, 8px 0 0 0 );
        }

        &_20 {
            @include rem( margin, 20px 0 );
        }
    }

    &--padding {

        &_0 {
            @include rem( padding, 0 );
        }

        &_left-0 {
            @include rem( padding-left, 0 );
        }

        &_15 {
            @include rem(padding, 15px);
        }

        &_15-0 {
            @include rem(padding, 0 15px);
        }

        &_30 {
            @include rem(padding, 30px);
        }

        &_30-0 {
            @include rem( padding, 30px 0 );
        }

        // mod added to change padding in 'kontakt cms' section
        &_40 {
            @include rem( padding, 40px 0 );
        }

        &_40-max {
            @include rem( padding, 40px );
        }
    }

    &--background {
        background: $color-light;
    }

    &--row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        @media #{$medium-up} {
            justify-content: space-between;
            flex-direction: row;
            flex-grow: 0;
            flex-shrink: 0;
        }
        &-reversed {
            @media #{$large-up} {
                flex-direction: row-reverse;

            }
        }
    }

    &--aligned {
        &-end {
            justify-content: flex-end;
        }

        &-center {
            justify-content: center;
        }
    }

    &--bordered {

        &-right {
            border-right: $border;

            &_lg-only {
                @media #{$large-up} {
                    border-right: $border;
                }
            }

            &_md {
                @media #{$medium-up} {
                    border-right: $border;
                }
            }
        }

        &-bottom {
            border-bottom: $border;
        }

        &-hoverable {
            border: $border;
            transition: box-shadow .2s ease;

            &:hover {
                box-shadow: 0 0 8px 0 rgba(34, 34, 34, 0.31)
            }
        }
    }

    &--hoverable {
        transition: background .2s;
        cursor: pointer;
        &:hover {
            background: $color-dark-red-90;
            #{$root}__paragraph {
                color: $color-white;
            }

            #{$root}__content-icon {
                fill: $color-white;
            }
            #{$root}__content-icon-arrow {
                fill: $color-white;
            }
        }
    }

    &--fixed-size {
        width: auto;
        margin: 0 auto;
        @media #{$medium-up} {
            @include rem( height, 90px );
            width: 100%;
        }
        @media #{$large-up} {
            @include rem( max-width, 400px );
        }
    }

    &--fixed-width {
        width: auto;
        margin: 0 auto;
        @media #{$medium-up} {
            @include rem( max-width, 400px );
        }
    }

    &--hidden {
        display: none;
        height: auto;
        position: absolute;
        @include rem(top, -20px);
        left: 0;
        right: 0;
        z-index: 4;
    }

    &--active {
        display: block;
    }

    // for checked work.php
    &--fixed-height {
        display: flex;
        align-items: center;

        &_95 {
            @include rem( height, 105px );
        }

        &_95 {
            @include rem( height, 95px );
        }

        &_75 {
            @include rem( height, 75px );
        }

    }

    &--width {
        &_max {
            max-width: $base-content-width;
            margin: 0 auto;
        }
    }

    //for contact and impressum

    &__headline {
        margin: 0;
        width: 100%;
        @include rem( padding-bottom, 20px );

        &--after {
            text-transform: uppercase;

            &-initial {
                text-transform: initial;
            }

            &::after {
                content: '';
                display: block;
                @include rem( width, 80px );
                @include rem( height, 3px );
                @include rem( margin, 20px 0 4px );
                background: $color-dark-red-80;

            }
        }

        &--no-padding {
            padding-bottom: 0;
        }

        &--sm {
            text-transform: initial;
        }
    }

    &__icon {
        fill: $color-grey;
        transform: skew(-20deg);
        @include rem( margin-bottom, 5px );

        &--big {
            @include rem(width, 30px);
            @include rem(height, 20px);
        }

        &--small {
            @include rem(width, 20px);
            @include rem(height, 15px);
            @include rem(margin-bottom, 5px);

        }

    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &--height {

            &_max-md {
                @media #{$medium-up} {
                    @include rem( max-height, 260px );
                }
            }
        }

        &--width {

            &_max {
                @include rem( max-width, 180px );
            }
        }
    }

    &__image-wrapper {
        display: block;
        width: 100%;
        height: auto;

        &--width {

            &_60-md-only {
                @media #{$medium-up} {
                    width: 60%;
                    @include rem( padding-right, 30px );
                }
            }

            &_max {
                @include rem( max-width, 200px );
            }
        }

        &--height {

            &_max {
                height: 100%;
                @include rem( max-height, 280px );
            }
        }
    }

    &__button {
        width: auto;
        @include rem( padding, 0 15px );
        @include rem( margin-right, 15px );
    }

    &__paragraph {
        margin: 0;
        width: 100%;
        transition: color .2s;
        line-height: 1.5rem;

        &--spaced {
            @include rem( padding-bottom, 15px );
        }

        &--uppercase {
            text-transform: uppercase;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: auto;
        width: 100%;

        &--padding {

            &_30 {
                @include rem( padding, 0 30px );
            }

            &_top-30 {
                @include rem( padding-top, 30px );
            }

            &_40-lg {
                // for news
                @include rem( padding-bottom, 15px );
                @media #{$large-up} {
                    @include rem( padding, 0 40px );
                }
            }

            &_max {
                @include rem( padding, 30px );
            }

            &_right-30 {
                @include rem( padding-right, 30px );
            }

        }

        &--margin {

            &_top {
                @include rem( margin-top, 8px );
            }

            &_30 {
                @include rem( margin, 30px 0 );
            }

            &_30-bottom  {
                @include rem( margin-bottom, 30px );
            }
        }

        &--align-left {
            align-items: flex-start;
            justify-content: flex-start;
        }


        &--width {

            &_max {
                max-width: $base-content-width;
                margin: 0 auto;
                @media #{$xlarge-up} {
                    @include rem( padding, 0 30px );
                }
            }

            &_40 {
                width: 100%;
                @media #{$medium-up} {
                    width: 50%;
                }
                @media #{$large-up} {
                    width: 40%;
                }
            }

            &_50 {

                @media #{$large-up} {
                    width: 50%;
                }
            }

            &_50-max {
                @include rem( padding, 30px );

                @media #{$large-up} {
                    justify-content: flex-start;
                    width: 50%;
                    @include rem( max-width, 600px );
                    @include rem( padding, 40px 30px );
                }
            }

            &_60 {
                width: 100%;
                @media #{$medium-up} {
                    width: 50%;
                }
                @media #{$large-up} {
                    width: 60%;
                }
            }
        }

        &--row {
            flex-direction: column;
            flex-wrap: wrap;
            @media #{$medium-up} {
                flex-direction: row;
            }
            @media #{$large-up}{
                flex-wrap: nowrap;
            }
        }

        &--columned {
            flex-direction: column;
        }

        &--centered {
            align-items: center;
            justify-content: space-around;
            flex-direction: column;
            width: 100%;
            height: 100%;
            @media #{$medium-up} {
                flex-direction: row;
            }
        }

        &--inline {
            display: inline;
        }

        &--spaced {
            &-around {
                justify-content: space-around;
            }

            &-between {
                justify-content: space-between;
            }
        }


        &--column-md-only {
            @media #{$medium-only} {
                flex-direction: column;
                justify-content: space-between;
                @include rem( height, 80px );
            }

        }

        &--column-sm-only {
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                flex-direction: column;
                justify-content: space-between;
                @include rem( height, 80px );
            }

        }

        &-icon {
            fill: $color-grey;
            @include rem( width, 50px );
            @include rem( height, 90px );
            @media #{$medium-up} {
                height: auto;
            }

            &--sm-fixed { //for checked icons work.php
                @include rem( height, 25px );
                @include rem( max-width, 30px );
                @include rem( margin-right, 15px );
            }

            &--small {
                @include rem( width, 30px );
                @include rem( height, 25px );
            }

            &--medium {
                @include rem( height, 35px );
            }

            &--big {
                @include rem( height, 45px );
            }

            &--large {
                @include rem( width, 100px );
                @include rem( height, 60px );
                @include rem( margin-right, 30px );
            }

            &--xlarge {
                @include rem( height, 65px );
                @include rem( width, 70px );
                @include rem( margin-right, 30px );

            }

            &--fill {
                &_initial {
                    fill: initial;
                }
            }

            &-arrow {
                @include rem( width, 10px );
                @include rem( height, 10px );
                @include rem( margin-left, 5px );
                display: inline;
                fill: $color-dark-red-80;
                transition: fill .2s;

                &:hover {
                    fill: $color-dark-red-90;
                }

                &--transformed {
                    transform: rotate(90deg);
                }
            }
        }

        &-icon-arrow, &-icon {
            transition: fill .2s;
        }
    }

    &__text {
        display: flex;
        width: 100%;
        height: auto;
        @include rem( padding-bottom, 30px );

        &--column {
            flex-direction: column;
        }

        &--width {

            &_auto {
                width: auto;
            }

            &_25 {
                @include rem( padding, 15px );

                @media #{$medium-up} {
                    width: 50%;

                }
                @media #{$large-up} {
                    width: 25%;
                    padding: 0;
                }
            }

            &_40 {
                @media #{$large-up} {
                    width: 40%;
                }

            }

        }

        &--aligned {

            &_center {
                align-items: center;
                justify-content: center;
            }

            &_left {
                justify-content: flex-start;
            }

            &_self-center {
                align-self: center;
            }
        }

        &--margin {

            &_30-bottom {
                @include rem( margin-bottom, 30px );
            }

            &_20-bottom {
                @include rem( margin-bottom, 30px );

            }
        }

        &--padding {

            &_0 {
                padding-bottom: 0;
            }

            &_8 {
                @include rem( padding, 8px );
            }

            &_15-0 {
                @include rem( padding, 15px 0 );
            }

            &_15 {
                @include rem( padding-bottom, 15px );
                @include rem( margin-bottom, 15px );
            }

            &_30 {
                @include rem( padding-bottom, 30px );
                @include rem( margin-bottom, 30px );
            }
        }

        &--space {
            // left-space  adjusts to text with icons
            &_left {
                @include rem( padding-left, 15px );
                @media #{$large-up} {
                    @include rem( padding-left, 5px );
                }
            }
        }

        &--bordered {

            &_bottom {
                border-bottom: $border;
            }

            &_section {
                @include rem( margin-bottom, 30px );
            }
        }


        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            height: auto;
            @include rem( padding-bottom, 15px );
            @include rem( padding-left, 15px );
            @media #{$large-up} {
                @include rem( padding-left, 5px );
            }

            &--padding {
                &_0 {
                    padding-bottom: 0;
                }

                &_10 {
                    @include rem( padding, 10px 0 );
                }
            }

            &--width {

                &_auto {
                    width: auto;
                }
            }

            &--space {

                &_left {

                    &-sm {
                        @include rem( padding-left, 8px );
                    }
                }
            }

            &--date {
                border-right: $border;
                @include rem( padding, 4px 15px 0 0 );
                @include rem( margin, 0 15px 15px 0 );
            }

            &--row {
                flex-direction: row;
            }
        }

        // IE11 fix
        .#{$namespace}bullet-list {
            &__item {
                .#{$namespace}paragraph {
                    width: 100%;
                }
            }
        }
    }

    &__icon-close  {
        background: $color-grey;
        transform: rotate( -45deg );
        @include rem( height, 20px );
        display: block;
        position: relative;
        @include rem( width, 1px );

        &::after {
            background: $color-grey;
            content: '';
            @include rem( height, 1px );
            @include rem( width, 20px );
            @include rem( left, -9px );
            @include rem( top, 9px );
            position: absolute;
        }
    }

    &__icon-close-wrapper {
        position: absolute;
        @include rem( right, -25px );
        @include rem( top, -15px );
        @include rem( width, 20px );
        @include rem( height, 20px );
    }

    &__icon-file {
        display: inline-block;
        fill: $color-dark-red-80;
        @include rem( width, 20px );
        @include rem( height, 20px );
        @include rem( margin, 0 0 -3px 7px );

        &:hover {
            fill: $color-dark-red-90;
        }
    }

    &__box {
        transform: skew( -20deg );
        @include rem( padding, 15px );
        width: auto;
        border: $border;
        height: 100%;
        display: flex;

        &--center {
            border: 0;
            border-top: 2px solid $color-grey;
            padding: 0;
            @include rem( margin, 0 15px );
            @include rem( width, 30px );
            top: calc( 50% - 2px);
            position: relative;
            height: 50%;
        }

        &-wrapper {
            transform: skew( 20deg );
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
