.#{$namespace}form {
    display: block;
    width: 100%;
    height: auto;
    @include rem( padding, 0 30px );

    &__fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    &__button-wrapper {
        @include rem( padding, 30px );
    }

    &__button {
        margin: 0 auto;
    }
}

