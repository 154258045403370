.#{$namespace}boxes-layout {
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    position: relative;
    max-width: $base-page-width;

    @media #{$large-up} {
        @include rem( height, 780px );
    }
    @media #{$xlarge-up} {
        @include rem( height, 940px );
    }

    &__button {
        display: inline-block;
        @include rem( padding, 0 15px );
        @media #{$medium-only} {
            @include rem( padding, 0 8px );
        }
        @media #{$medium-up} {
            max-width: 100%;
        }

        &--auto-height {
            height: auto;
        }

        &-text {
            text-transform: capitalize;
            font-weight: 500;
            line-height: 1;
            @include rem( padding, 8px 0 );
        }

        &--only-icon {
            @include rem( padding, 0 10px );
            vertical-align: bottom;

            .sg-button__icon {
                margin-left: 0;
            }
        }
    }

    &__background {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 98.5%;
        margin: 0 auto;
        height: 100%;
        z-index: 10;
        @include rem( box-shadow, inset 0 -200px 150px -130px );

    }

    &__form {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    &__container {
        //margin: .75% 0 0;
        @include rem( padding, 8px 0 );
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        @media #{$medium-up} {
            padding: .75% 0;
        }
        @media #{$large-up} {
            flex-direction: row;
        }

    }

    &__info-box {
        display: block;
        position: absolute;
        z-index: 11;
        @include rem( bottom, 30px );
        height: auto;
        width: 100%;
        @include rem( padding-right, 20px );
        @media #{$large-up} {
            padding-right: 0;
            width: auto;
        }

        &--content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            bottom: 0;
            @include rem( padding, 30px );
            @media #{$large-up} {
                @include rem( padding, 0 30px 30px );

            }
        }

        &-wrapper {
            display: flex;
            @include rem( padding-left, 15px );
            align-items: flex-end;
            justify-content: flex-start;
            @media #{$medium-up} {
                align-items: flex-end;
                @include rem( padding-left, 30px );

            }
        }

    }

    &__headline {
        line-height: 1;

        &-inner {
            @include rem( padding-right, 15px );
            line-height: 1;
        }
    }

    &__section {
        width: 100%;
        height: 100%;
        display: inline;
        @media #{$large-up} {
            width: 50%;
        }

        &-double-wrapper {

            display: flex;
            flex-direction: column;
            @media #{$medium-up} {
                flex-direction: row;
                //margin-bottom: 1.5%;
                padding-bottom: 1.5%;
                height: 40%;
            }

        }

        &-single-wrapper {
            margin-bottom: 1.5%;
            height: 60%;
        }

        &-item {
            position: relative;
            display: block;
            height: 100%;
            //width: 98.5%;
            width: 100%;
            //margin: 0 .75% 1.5%;
            padding: 0 .75% 1.5%;
            @media #{$medium-up} {
                //margin: 0 .75%;
                padding: 0 .75%;
                width: 50%;
                height: 100%;
            }

            &--hero {
                //width: 98.5%;
                //margin: 0 .75%;
                width: 100%;
                padding: 0 .75%;
            }
        }

        &-link-wrapper {
            display: block;
            cursor: pointer;
            height: 100%;
            text-decoration: none;
            width: 100%;
            outline: none;
        }

        &-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            display: block;
        }

    }
}
