.#{$namespace}logo {
    display: block;
    @include rem( max-width, 265px );
    width: 100%;
    @include rem( height, 65px );
    @include rem( margin-top, 5px );
    @media #{$large-up} {
        margin: 0 0 0 auto;
    }

    .st0 {
        clip-path: url( '#SVGID_2_' );
    }

    .st1 {
        clip-path: url( '#SVGID_2_' );
        fill: #ed1c24;
    }
}
