.#{$namespace}bullet-list {
    $root: &;
    padding: 0;
    list-style: none;
    @include rem( margin-left, 15px );
    @include rem( margin-top, 15px );

    &--width-40 {
        width: 40%;
    }

    &__item {
        display: flex;
        align-items: center;
        list-style: none;
        position: relative;
        line-height: initial;
        @include rem( padding-bottom, 5px );

        &:before {
            display: block;
            position: relative;
            transform: skew( -20deg );
            @include rem( width, 5px );
            @include rem( height, 5px );
            padding: 0;
            background: $color-dark-red-80;
            content: '';
            border: 1px solid $color-dark-red-80;
            @include rem( left, -15px );
            transition: color .2s, border-color .2s;
        }

        &--numbered {
            list-style: decimal;
            color: $color-grey;

            &:before {
                display: none;
            }
        }

        ul {

            li {
                list-style-type: lower-alpha;

                &:before {
                    content: none;
                }

                ul {

                    li {
                      list-style-type: lower-roman;
                    }
                }
            }
        }

        &-text {
            margin: 0;
        }

        &--width-30 {
            width: calc( 50% - 15px );
            @include rem( margin-right, 15px );
            @media #{$medium-up} {
                width: calc( 33.333% - 15px );
            }

            > #{$root}__item-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &--bottom-space {
            @include rem( margin-bottom, 5px );
            @media #{$medium-up} {
                @include rem( margin-bottom, 10px );
            }
        }
    }
}
