.#{$namespace}member-data {

    &__box {
        height: auto;
        width: auto;

        &-text {
            @include rem( padding, 4px);
            @media #{$large-up} {
                @include rem( padding, 8px);
            }
        }
    }
}