.#{$namespace}article-list {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: $base-content-width;
    height: auto;
    background: $color-white;
    @include rem( padding, 30px );
    overflow-x: auto;
    overflow-y: auto;

    &__wrapper {
        width: 100%;
        height: 100%;
        @include rem( max-height, 200px );
        @include rem( min-height, 60px );
        display: flex;
        flex-shrink: 1;
        flex-wrap: wrap;
        margin: 0;
        @include rem( padding-left, 20px );
        overflow-y: auto;
        overflow-x: hidden;
    }

}