.#{$namespace}gallery-layout {
    $root: &;
    display: flex;
    margin: 0;
    overflow: hidden;
    position: relative;
    max-width: $base-page-width;
    width: 100%;
    padding: 1.5% 0 0;
    @media #{$medium-up} {
        padding: .75% 0 0;
    }

    &--no-padding {
        padding: 0;
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        @media #{$medium-up} {
            padding: 0 .375%;
            flex-direction: row;
            @include rem( height, 650px );
        }
        @media #{$large-up} {
            @include rem( height, 960px );
        }
        @media #{$xlarge-up} {
            height: auto;
        }

        &--swiper-single {
            @include rem(height, 350px);

            @media #{$medium-up} {
                flex-direction: row;
            }
            @media #{$large-up} {
                @include rem(height, 420px);
            }
            @media #{$xlarge-up} {
                @include rem(height, 460px);
            }

        }

        &--single {
            height: auto;
            padding: 0;
            @media #{$medium-up} {
                @include rem(height, 420px);
            }
            @media #{$large-up} {
                @include rem(height, 480px);
            }
            @media #{$xlarge-up} {
                @include rem(height, 600px);
            }
        }

    }

    &--swiper {
        overflow: hidden;
        width: 90%;
        margin: 0 auto;
        max-width: $base-content-width;
        @media #{$medium-up} {
            @include rem(height, 500px);
        }
        @media #{$large-up} {
            @include rem(height, 650px);
            max-width: $base-container-width ;
        }
        @media #{$xlarge-up} {
            @include rem(height, 800px);
            max-width: $base-page-width;
        }

    }

    &__section {
        width: 100%;
        height: auto;
        display: block;

        @media #{$medium-up} {
            @include rem( max-height, 460px );
            height: 50%;
            width: 50%;
        }
        @media #{$large-up} {
            max-height: none;
        }

        &--swiper {
            width: 100%;
            height: 100%;
        }

        &--single {
            @media #{$medium-up} {
                height: 100%;
            }
        }
    }

    &__item {
        position: relative;
        display: block;
        padding: 0 .75%;
        width: 50%;
        height: 100%;
        cursor: pointer;



        &--hero {
            display: none;
            height: 0;
            @media #{$medium-up} {
                display: block;
                height: 100%;
                width: 100%;
                padding: 0 .75%;
            }
        }

        &--width {
            &_100-sm {
                @media #{$xsmall-only}, #{$small-only} {
                    padding: .75%;
                    width: 100%;
                }
            }
        }

        .#{$namespace}video {
            .#{$namespace}teaser {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;
                background: 0;

                &__image {
                    top: calc( 50% - 25px );
                    left: calc( 50% - 25px );
                    position: absolute;
                    margin: 0;
                    border-radius: 50%;
                    background-color: rgba( 0, 0, 0, .3 );
                    @include rem( padding, 5px );

                    &--player {

                        @media #{$medium-up} {
                            top: calc( 50% - 40px);
                            left: calc( 50% - 40px);
                        }
                    }

                    &--player-sm {

                        @media #{$medium-up} {
                            top: calc( 50% - 30px );
                            left: calc( 50% - 30px );
                        }
                    }
                }
            }
        }


    }

    &__items-wrapper {
        flex-wrap: wrap;
        padding-bottom: 1.5%;
        height: 100%;

        &--double {
            display: flex;
            height: 50%;
        }

        &--double-mod {
            display: flex;
            flex-direction: column;
            height: 50%;
        }
    }



    &__image-wrapper {
        display: block;
        width: 100%;
        height: 100%;

        &--compat-object-fit {
            //fallback for IE & edge object-fit
            background-size: cover;
            background-position: center center;
        }
    }

    &__image {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__arrow {

        &--left {

            @include rem( left, 20px );
            @media #{$medium-up} {
                @include rem( left, 10px );
            }
            @media #{$large-up} {
                @include rem( left, 5px );
            }
            @media #{$xlarge-up} {
                @include rem( left, -60px );
            }
        }

        &--right {

            @include rem( right, 20px );
            @media #{$medium-up} {
                @include rem( right, 10px );
            }
            @media #{$large-up} {
                @include rem( right, 5px );
            }
            @media #{$xlarge-up} {
                @include rem( right, -60px );
            }

        }
    }
}

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8; }

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden; }

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box; }

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
    display: none; }

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto; }

.mfp-ajax-cur {
    cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
    cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }

.mfp-loading.mfp-figure {
    display: none; }

.mfp-hide {
    display: none !important; }

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044; }
.mfp-preloader a {
    color: #CCC; }
.mfp-preloader a:hover {
    color: #FFF; }

.mfp-s-ready .mfp-preloader {
    display: none; }

.mfp-s-error .mfp-content {
    display: none; }

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation; }

button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.mfp-close {
    visibility: hidden;
    width: 0;
    height: 0;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}
button.mfp-close {
    display: none;
}
.mfp-close:hover,
.mfp-close:focus {
    opacity: 1; }
.mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
    color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%; }

.mfp-counter {
    display: none }

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 20%;
    margin-top: 0;
    padding: 0;
    width: 60%;
    height: 60%;
    -webkit-tap-highlight-color: transparent;
    @media #{$large-up} {
        width: 90px;
        height: 110px;
        top: 50%;
        margin-top: -55px;
    }
}
.mfp-arrow:active {
    margin-top: 0;
    @media #{$large-up} {
        margin-top: -54px;
    }
}
.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}
.mfp-arrow:before,
.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0;
    margin-left: 0;
    border: medium inset transparent;
    @media #{$large-up} {
        margin-top: 35px;
        margin-left: 35px;
    }
}
.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
    left: 8px;
    @media #{$large-up} {
        left: 0;
    }
}
.mfp-arrow-left:after, .mfp-arrow-left:before, .mfp-arrow-right:after, .mfp-arrow-right:before {

    content: '';
    height: 25px;
    width: 4px;
    background: $color-light-grey;
    border: 0;
    opacity: 1;
    @media #{$large-up} {
        width: 2px;
        margin-left: 39px;
    }
}


.mfp-arrow-left:after {
    top: 45%;
    transform: rotate(45deg);
    @media #{$large-up} {
        top: -17px;
    }

}
.mfp-arrow-left:before {
    transform: rotate(-45deg);
    top: calc( 45% + 15px );
    @media #{$large-up} {
        top: 0;
    }
}

.mfp-arrow-right {
    right: 8px;
    @media #{$large-up} {
        right: 0;
    }
}
.mfp-arrow-right:after {
    transform: rotate(45deg);
    top: calc( 45% + 15px );

    @media #{$large-up} {
        top: 0;
    }
}
.mfp-arrow-right:before {
    content: '';
    height: 25px;
    width: 4px;
    background: $color-light-grey;
    transform: rotate(-45deg);
    border: 0;
    opacity: 1;
    top: 45%;

    @media #{$large-up} {
        width: 2px;
        top: -17px;
    }
}


.mfp-arrow-left:after, .mfp-arrow-left:before {
    margin-left: 50px;
}

.mfp-arrow-right:after, .mfp-arrow-right:before {
    margin-right: 50px;
    right: 0;
    left: initial;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
.mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
    line-height: 0; }
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
.mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto; }

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px; }

.mfp-image-holder .mfp-content {
    max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
         * Remove all paddings around the image on small screen
         */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0; }
    .mfp-img-mobile img.mfp-img {
        padding: 0; }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0; }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px; }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0; }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px; }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0; }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75); }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0; }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%; }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px; }
}

