.#{$namespace}headline {
    margin: 0;
    font-weight: 400;

    &--white {
        color: $color-white;
    }

    &--dark {
        color: $color-darkest;
    }

    &--dark-grey {
        color: $color-dark-grey;
    }

    &--red {
        color: $color-dark-red-80;
    }

    &--italic {
        font-style: italic;
    }

    &--size {

        &_12 {
            //font-size: 9.558rem; //180px
            @include responsive-font(15vw, 120px, 270px, 180px);
        }

        &_10 {
            //font-size: 3.186rem; //60px
            @include responsive-font(5vw, 40px, 90px, 60px);
        }

        &_8 {
            //font-size: 3.071rem; //50px
            @include responsive-font(4vw, 35px, 70px, 50px);
        }

        &_6 {
            //font-size: 2.286rem; //32px
            @include responsive-font(3vw, 20px, 46px, 32px);
        }

        &_4 {
            font-size:  1.5rem; //28px
            @media #{$medium-up} {
                font-size:  2rem;
            }
            @media #{$xlarge-up} {
                @include responsive-font(3vw, 18px, 38px, 28px);
            }
        }
        &_2 {
            font-size: 1.286rem; //18px
            @media #{$xlarge-up} {
                @include responsive-font(3vw, 14px, 24px, 18px);
            }
        }

    }

    &--bold {
        font-weight: 700;
    }

    &--light {
        font-weight: 300;
    }

}
