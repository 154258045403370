.#{$namespace}list {
    $root: &;
    list-style: none;
    text-transform: capitalize;
    padding-left: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0;

    @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
        @include rem( padding, 20px );
        width: 100%;
    }

    @media #{$large-up} {
        height: 100%;
        flex-direction: row;
    }

    &--small-horizontal {
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            flex-direction: row;
            justify-content: center;
            align-items: center;
            @include rem( padding, 5px 0 );
        }
    }

    &__submenu-wrapper {
        display: none;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background: $color-white;
        @media #{$large-up} {
            display: block;
            visibility: hidden;
            background: $color-light;
            @include rem( box-shadow, 0 6px 4px 0 rgba( 0, 0, 0, .15 ) );
            position: absolute;
            @include rem( margin-top, 1px );

        }
    }

    &__submenu-inner-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        @media #{$large-up} {
            max-width: $base-content-width;
            flex-direction: row;
            margin: 0 auto;
            @include rem( padding, 35px 20px );
        }
    }

    &__submenu {
        display: block;
        text-transform: uppercase;
        list-style: none;
        width: 100%;
        @include rem( padding-left, 20px );
        @media #{$large-up} {
            padding-left: 0;
            width: 33%;
        }

        &-child {
            list-style: none;
            width: 100%;
            @include rem( padding-left, 20px );
        }

        &-item {
            padding: 10px 0;
            @media #{$large-up} {
                border-bottom: 1px solid $color-light-grey;
                margin-right: 25%;
                @include rem( height, 40px );
            }

            &:last-of-type {
                border-bottom: 0;
            }

            &--child {
                @include rem( padding, 5px 0 );
                border-bottom: 0;
            }

            &:hover {
                #{$root}__submenu-link {
                    color: $color-dark-red-90;
                    @media #{$large-up} {
                        font-size: 1.15rem;
                    }
                }
            }
        }

        &-icon {
            height: 100%;
        }

        &-quote {
            display: none;

            @media #{$large-up} {
                display: block;
                position: relative;
                text-transform: none;
                @include rem( margin-top, 30px );
                @include rem( padding-right, 60px );
            }

            &-icon {
                display: none;

                @media #{$large-up} {
                    display: inline-block;
                    fill: $color-grey;
                    transform: skew(-20deg);
                    @include rem( margin-bottom, 5px );
                    @include rem(width, 18px);
                    @include rem(height, 12px);
                }
            }
        }

        &-link {
            text-transform: uppercase;
            font-weight: 500;
            margin: 0;
            font-size: 1rem;
            color: $color-darkest;
            transition: font-size .2s ease, color .2s;

            &--child {
                font-weight: 400;
            }
        }

        &--left {
            flex-direction: column;
        }

        &--right {
            flex-direction: column;
        }

        &-media-wrapper {
            display: none;
            @media #{$large-up} {
                display: block;
                position: relative;
                width: 33%;
                @include rem( min-height, 245px );
                @include rem( padding, 8px );
            }

            &--width {

                &_300 {
                    padding: 0;
                    @media #{$medium-up} {
                        @include rem( max-width, 360px );
                    }
                }
            }

            &--sm-visible {
                display: block;
            }
        }

        &-media-image {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__item {
        display: flex;
        height: auto;
        text-transform: capitalize;

        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            padding: 10px 0;
            @include rem( padding, 10px 0 );
            flex-direction: column;
        }

        @media #{$large-up} {
            height: 100%;
        }

        &--bordered {
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                border-bottom: 1px solid $color-light-grey;

                &:last-of-type {
                    border-bottom: 0;
                }
            }
        }

        &--bordered-right {

            &::after {
                display: none;
                @media #{$large-up} {
                    content: '';
                    background: $color-light-grey;
                    transform: rotate( 20deg );
                    display: flex;
                    align-self: center;
                    height: 1.286rem;
                    @include rem( width, 1px );
                    position: relative;
                    right: 0;
                    @include rem( margin, 0 -16px 0 15px );
                }
            }
        }

        //&--active {
        //    @media #{$large-up} {
        //        border-bottom: 1px solid $color-light;
        //    }
        //
        //    &::before, &::after {
        //        display: none;
        //        @media #{$large-up} {
        //            content: '';
        //            background: $color-light-grey;
        //            transform: rotate( 20deg );
        //            @include rem( margin, 0 -16px 0 15px );
        //            @include rem( width, 1px );
        //            @include rem( height, 86px );
        //            display: flex;
        //            align-self: center;
        //        }
        //    }
        //}
        //
        //&--hovered {
        //    color: $color-dark-red-90;
        //}

        &--hidden-sm {
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                display: none;
            }
        }

        &-text {
            margin: 0;
            @include rem( padding, 10px 10px 10px 0 );

            @media #{$large-up} {
                display: flex;
                @include rem( padding, 10px 10px 10px 35px );
                @include rem( margin-left, 10px );
                align-self: center;
            }
        }

        &--hoverable {
            &:hover {

                border-bottom: 1px solid $color-light;
                color: $color-dark-red-90;

                &::before, &::after {
                    display: none;
                    @media #{$large-up} {
                        content: '';
                        background: $color-light-grey;
                        transform: rotate( 20deg );
                        @include rem( margin, 0 -16px 0 15px );
                        @include rem( width, 1px );
                        @include rem( height, 86px );
                        display: flex;
                        align-self: center;
                    }
                }

                @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                    > #{$root}__submenu-wrapper {
                        display: block;
                    }
                }
                @media #{$large-up} {
                    > #{$root}__submenu-wrapper {
                        visibility: visible;
                    }


                }

            }
        }
    }
}
