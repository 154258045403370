.#{$namespace}article {

  &__paragraph {

    a:not(.sg-link) {
      -webkit-transition: color .2s ease;
      transition: color .2s ease;
      color: $color-dark-red-80;
      cursor: pointer;
      text-decoration: none;
      outline: none;
    }

    strong {
      color: $color-dark-grey;
      font-weight: 400;
    }

  }

}

.sg-paragraph {
    a:not(.sg-link) {
        -webkit-transition: color .2s ease;
        transition: color .2s ease;
        color: $color-dark-red-80;
        cursor: pointer;
        text-decoration: none;
        outline: none;
    }

    strong {
      font-weight: 400;
    }
}
