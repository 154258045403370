.#{$namespace}section {
    display: block;
    position: relative;
    width: 100%;
    max-width: $base-page-width;
    height: auto;
    margin: 0 auto;

    &--fixed {
        @include rem( min-height, 600px );
    }

    &__container {
        @include rem( margin, 8px );
        background: $color-light;

        &--no-margin {
            margin: 0;
        }
        
        &--white {
            background: $color-white;
        }
    }

    &__wrapper {
        max-width: $base-page-width;
        display: block;
        margin: 0 auto;
        height: auto;
        @include rem( padding, 0 8px );

        // IE11 fix
        > .#{$namespace}paragraph {
            width: 100%;
        }

        &--white {
            background: $color-white;
        }

        &--small {
            max-width: $base-content-width;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include rem( padding, 0 );
        }

        &--medium {
            max-width: $base-container-width;
        }

        &--padding {

            @include rem( padding, 0 20px 20px );
            @media #{$large-up} {
                @include rem( padding, 0 40px 40px );
            }

            &_0 {
                padding: 0;
            }

            &_4 {
                @include rem( padding, 0 4px );
            }

            &_mod-max {
                @media #{$large-up} {
                    @include rem( padding, 0 90px );
                }
            }

            &_max {
                @include rem( padding, 20px );
                @media #{$large-up} {
                    @include rem( padding, 40px );
                }
            }

            &_40 {
                @include rem( padding, 20px );
                @media #{$large-up} {
                    @include rem( padding, 0 40px );
                }
            }

            &_bottom {
                @include rem( padding-bottom, 20px );
                @media #{$large-up} {
                    @include rem( padding-bottom, 40px );
                }
            }

            &_top {
                @include rem( padding-top, 20px );
                @media #{$large-up} {
                    @include rem( padding-top, 40px );
                }
            }
        }

        &--aligned {
            &_left {
                align-items: flex-start;
            }
        }
    }

    &__headline {
        display: flex;
        flex-direction: column;
        @include rem( padding, 20px );
        text-align: center;
        max-width: $base-content-width;
        margin: 0 auto;
        @media #{$medium-up} {
            @include rem( padding, 40px 20px );
        }
        // IE11 fix
        .#{$namespace}paragraph {
            width: 100%;
        }

        &--aligned-left {
            width: 100%;
            margin: 0;
            align-items: flex-start;
            text-align: left;
            @media #{$medium-up} {
                padding-left: 0;
                align-items: flex-start;
                align-self: flex-start;
            }
        }

        // mod added to change padding-bottom for sigle line hadline with small content within section
        &--single {
            @include rem( padding-bottom, 10px );
        }

        &--hero {
            @include rem( padding, 20px 0 );
            margin: 0 auto;
        }

        &--bordered {
            border-bottom: $border;
        }

        &--margin {

            &_30 {
                @include rem( margin, 0 30px );
                width: calc( 100% - 60px );
            }
        }

        &--row {
            @media #{$medium-up} {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                > .#{$namespace}paragraph {
                    width: auto;
                }
            }
        }

        &--row-start {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &--padding {
            &_sm {
                @media #{$xsmall-only}, #{$small-only} {
                    @include rem( padding-left, 30px );
                }
            }

            &_0-sm {
                @media #{$xsmall-only}, #{$small-only} {
                    @include rem( padding, 20px 0 );
                }
            }
        }

        &-icon {
            display: inline-block;
            @include rem( width, 150px );
            @include rem( height, 130px );
            @include rem( margin, 0 20px );
            @media #{$medium-up} {
                @include rem( margin, 0 20px 0 60px );
            }
        }


    }

    &__paragraph {
        margin: 0;
        width: auto;
    }

    &__column {
        display: block;
        position: relative;
        width: auto;

        &--padding {
            &_top {
                @include rem( padding-top, 20px );
                @media #{$large-up} {
                    @include rem( padding-top, 40px );

                }
            }
        }

        &-image {
            display: block;
            width: 100%;
            height: 100%;

            &--on-resize {
                display: block;
                @include rem( max-height, 400px );
                @include rem( min-height, 380px );
                min-width: 100%;
                width: auto;
                height: auto;

                @media #{$large-up} {
                    width: 100%;
                    height: 100%;
                    max-height: none;
                    min-height: 0;
                }
            }

            &--height {

                &_fixed {

                    &-sm-only {
                        @media #{$xsmall-only}, #{$small-only} {
                            max-height: none;
                            @include rem( height, 530px );
                        }
                    }
                }
            }
        }
    }
}
