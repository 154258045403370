.#{$namespace}footer {
    display: block;
    width: 100%;
    max-width: $base-page-width;
    height: auto;
    background: $color-darkest;
    margin: 0 auto;
    position: relative;

    @media #{$large-up} {
        @include rem( height, 80px );
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: $base-content-width;
        margin: 0 auto;
        height: 100%;

        @media #{$large-up} {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            @include rem( height, 80px );
            @include rem( padding, 0 40px );
        }
    }

    &__section {
        display: flex;

        &--column-sm {
            flex-direction: column;
            @media #{$large-up} {
                flex-direction: row;
            }
        }
    }

    &__link {
        padding-left: 0;
        @include rem( padding-right, 20px );
        margin-left: 0;
        display: flex;
        align-items: center;

        .sg-link__icon {
            pointer-events: none;
        }
    }
}
