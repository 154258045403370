.#{$namespace}login-form {
    display: block;
    $root: &;
    position: relative;
    width: 100%;
    overflow: hidden;
    @include rem( height, 300px );
    @media #{$medium-up} {
        @include rem( height, 100% );
        @include rem( max-height, 360px );
    }

    &--height {
        &_max {
            @media #{$medium-up} {
                @include rem( max-height, 320px );
                @include rem( min-height, 320px );
            }

            #{$root}__image {
                @include rem( min-height, 320px );
            }
        }
        &_max-md-only {
            @include rem( min-height, 290px );
            @media #{$medium-only} {
                min-height: auto;
                @include rem( max-height, 360px );
            }
        }
    }

    &--margin {
        &_bottom {
            @include rem( margin-bottom, 8px );
        }
    }



    &__image {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 100%;
        z-index: 1;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        @include rem( padding, 30px );
        z-index: 2;

        &--padding {
            &_sm {
                @include rem( padding, 15px );
            }

            &_sm-small-only {
                @media #{$xsmall-only}, #{$small-only} {
                    @include rem( padding, 15px );
                }
            }

            &_fixed {
                @include rem( padding, 30px );
            }
        }

        &--hidden {
            z-index: -1;
        }

        &--register {
            @include rem( padding, 15px );
        }
    }

    &__paragraph {
        margin: 0;

        &--margin {
            &_bottom {
                @include rem( margin-bottom, 10px );
            }
        }
    }

    &__link {
        text-align: right;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @media #{$medium-up} {
            @include rem( padding-top, 20px );
        }
        @media #{$large-up} {
            @include rem( padding-top, 10px );
        }
        @media #{$xlarge-up} {
            @include rem( padding-top, 20px );
        }

        &--aligned {

            &_right {
                justify-content: flex-end;
            }
            
        }

    }

    &__wrapper-info {
        display: block;
        background: rgba( 255, 255, 255, .6 );
        @include rem( padding, 8px );
    }

    &__table {
        width: 100%;

    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        @include rem( width, 20px );
        @include rem( height, 20px );
        @include rem( margin, 6px );
        fill: $color-grey;
        @media #{$large-up} {
            @include rem( margin, 8px );
        }

        &-file {
            display: inline-block;
            @include rem( width, 15px );
            @include rem( height, 15px );
            fill: $color-light-grey;
        }
    }

    &__form {
        @include rem( padding, 5px 0 0 );
        @media #{$medium-up} {
            @include rem( padding, 15px 0 0 );
        }
        @media #{$large-up} {
            @include rem( padding, 10px 0 0 );
        }
        @media #{$xlarge-up} {
            @include rem( padding, 20px 0 0 );
        }

        &::-ms-clear {
            display: none;
        }

        &::placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            font-size: 0.8571rem;
        }

        &-input {
            position: relative;
            @include rem( margin, 10px 0 );
            @media #{$large-up} {
                @include rem( margin, 0 0 10px );
            }
            &--last {
                @media #{$large-only} {
                    @include rem( margin, 0 );
                }
            }

        }

        &-element {
            border: 0;
            font-size: 1rem;
            width: 100%;
            @include rem( padding, 10px 5px 10px 35px );
            outline: 0;
            color: $color-darkest;
            border-radius: 0;
            @media #{$medium-up} {
                @include rem( padding, 7px 5px 7px 35px );
            }
            @media #{$large-up} {
                @include rem( padding, 10px 5px 10px 35px );
            }
        }
    }

    &__button-register {
        @media #{$large-only} {
            @include rem( padding, 0 4px );
        }
    }
}
