.#{$namespace}header {
    $root: &;
    width: 100%;
    @include rem(height, 80px);
    margin: 0 auto;
    z-index: 15;
    position: relative;

    @media #{$large-up} {
        border-bottom: 1px solid $color-white;
        background-color: rgba( 255, 255, 255, .95 );
        position: relative;

        &:hover {
            background-color: $color-light;
            border-bottom: $border;
        }
    }


    &__list {
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            background: $color-white;
        }
        @media #{$large-up} {
            height: calc(100% + 1px);
        }
    }

    &__wrapper {
        max-width: $base-content-width;
        margin: 0 auto;
        z-index: 2;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            border-bottom: 1px solid $color-light-grey;
        }
    }

    &__paragraph {

        &--small {
            display: inline-block;
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                text-transform: uppercase;
                color: $color-grey;
                font-size: .8571rem;
                padding: 0;
                @include rem( margin, 5px 0 0 );

            }
            @media #{$large-up} {
                @include rem( padding, 0 10px 0 5px );
            }
        }
    }

    &__search {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        align-self: center;
        @media #{$large-up} {
            flex-direction: row;
        }

        &::after {
            @media #{$large-up} {
                content: '';
                background: $color-light-grey;
                transform: rotate( 20deg );
                display: flex;
                align-self: center;
                height: 1.286rem;
                @include rem( width, 1px );
                position: relative;
                right: 0;
                margin: 0;
            }
        }
        &-icon {
            display: inline-block;
            fill: $color-grey;
            @include rem( width, 20px );
            @include rem( height, 20px );
            @media #{$large-up} {
                margin: 0;
            }

            &--clicked {
                fill: $color-dark-red-90;
            }
        }
    }

    &__burger {
        cursor: pointer;
        fill: $color-darkest;
        @include rem( width, 30px );
        @include rem( height, 30px );

        &--clicked {
            fill: $color-dark-red-90;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        height: auto;
        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            visibility: hidden;
            width: 100%;
            top: 100%;
            position: absolute;
            left: 0;
            right: 0;
        }
        @media #{$large-up} {
            height: calc( 100% + 1px );
        }
    }

    &__aside {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 100%;

        @media #{$large-up} {
            @include rem(padding-right, 40px);
            min-width: 30%;
            flex-direction: row;
        }

        &-elements {
            display: flex;
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                height: calc(100% + 1px);
                @include rem(width, 80px);
                justify-content: center;
                border-bottom: 1px solid transparent;

            }
            @media #{$large-up} {
                display: flex;
                align-items: center;
                @include rem(margin-right, 10px);
                @include rem(min-width, 110px);
                width: auto;
            }

            @media #{$xlarge-up} {
                @include rem(margin-right, 20px);
            }

            &--active {
                border-bottom-color: $color-white;
            }
        }

        &-logo {
            display: flex;

            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                align-items: center;
                height: 100%;
                @include rem(padding, 0 15px);
                border-right: 1px solid $color-light-grey;
            }

            @media #{$large-up} {
                width: 70%;
                align-self: flex-end;
            }
            @media #{$xlarge-up} {
                width: 100%;
            }
        }
    }

    &__menu-icon {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        @include rem(width, 80px);
        height: calc(100% + 1px);
        border-right: 1px solid $color-light-grey;
        border-bottom: 1px solid transparent;

        &-active {
            border-bottom-color: $color-white;
        }

        @media #{$large-up} {
            display: none;
        }
    }



}
