.#{$namespace}video-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: auto;
    @include rem( padding-bottom, 40px );
    overflow: hidden;
    @media #{$large-up} {
        flex-direction: row;
        @include rem(height, 530px);
    }

    &__player {
        top: calc( 50% - 40px);
        left: calc( 50% - 40px);
        z-index: 1000;
        background: $color-darkest;
        opacity: .9;
    }

    &__right {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        width: 100%;
        height: 100%;
        @include rem( padding, 17px 17px 0 );
        @include rem( margin-top, 8px );
        background: $color-white;
        overflow-y: auto;
        @include rem( min-height, 330px );
        @include rem( max-height, 360px );

        @media #{$large-up} {
            width: 30%;
            margin-top: 0;
            max-height: none;
        }

        @media #{$large-up} {
            width: 35%;
        }

        &-view-count {
            margin: 0;
            font-weight: 400;
            @media #{$medium-only} {
                font-size: 1rem;
            }
        }

        &-item {
            position: relative;
            z-index: 3;
            display: flex;
            width: 100%;
            height: auto;
            @include rem( padding-bottom, 17px );
            @include rem( min-height, 130px );
            @media #{$medium-up} {
                @include rem( min-height, 150px );
            }
            @media #{$large-up} {
                @include rem( min-height, 120px );
            }
        }

        &-title {
            margin: 0;
            font-weight: 500;
            @media #{$medium-only} {
                font-size: 1rem;
            }
        }

        &-channel-name {
            margin: 0;
            font-weight: 400;
            @media #{$medium-only} {
                font-size: 1rem;
            }
        }

        &-video-container {
            width: 45%;
            position: relative;
            display: flex;
            justify-content: center;
            z-index: 1000;
            min-height: 100%;
            @media #{$medium-up} {
                width: 50%;
            }

        }

        &-description {

            @include rem( padding-left, 17px );
            width: 55%;
            height: 100%;
        }
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        &--stop-propagation {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    &__left {
        display: inline-block;
        width: 100%;
        height: 100%;
        @media #{$large-up} {
            @include rem( margin-right, 8px );
            width: 65%;
        }

        &-text-view-count {
            margin: 0;
            visibility: hidden;
            height: 0;
        }

        &-video {
            height: 100%;
        }

        &-name {
            display: flex;
            align-items: center;
        }

        &-video-wrapper {
            height: calc( 100% - 70px );
            position: relative;
        }

        &-video-container {
            position: relative;
            display: flex;
            justify-content: center;
            z-index: 1000;
            padding-bottom: 53.25%; /* 16:9 */
            height: 0;
            min-height: 100%;

        }

        &-title {
            @include rem( padding, 0 15px );
        }

        &-text {
            text-transform: uppercase;
            display: none;
            @media #{$medium-up} {
                display: flex;
            }
            @media #{$medium-only} {
                @include rem( margin-right, 5px );
            }
        }

        &-description {
            @include rem( height, 70px );
            @include rem( padding, 15px );
            justify-content: space-between;
            background: $color-white;
            display: flex;
            @media #{$medium-up}  {
                @include rem( padding, 10px 15px );
            }
        }

        &-media {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 10%;
            @media #{$medium-up} {
                min-width: 20%;
            }
        }

        &-headline {
            @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
                font-size: .8rem;
            }
            @media #{$medium-only} {
                font-size: 1rem;
            }
            margin: 0;
            font-weight: 400;
        }

        &-img {
            visibility: hidden;
            height: 0;
        }
    }

}
