.#{$namespace}video {
    display: block;
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--height {

        &_max {
            @include rem( max-height, 300px );
            @media #{$medium-up} {
                @include rem( max-height, 370px );
            }
        }

    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;

        &-video {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &__border {
        display: block;
        position: absolute;
        z-index: 10;
        background: transparent;
        width: calc( 100% - 20px );
        height: calc( 100% - 20px );
        @include rem( top, 10px );
        @include rem( left, 10px );
        border: 1px solid $color-dark-red-80;
    }
}
