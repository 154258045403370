.#{$namespace}paragraph {
    font-size: 1rem; //14px
    font-weight: 300;

    &--bold {
        font-weight: 400;
    }

    &--italic {
        font-style: italic;
    }

    &--white {
        color: $color-white;
    }

    &--dark {
        color: $color-darkest;
    }

    &--red {
        color: $color-dark-red-80;
    }

    &--dark-grey {
        color: $color-dark-grey;
    }

    &--grey {
        color: $color-grey;
    }

    &--light-grey {
        color: $color-light-grey;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--align-right {
        text-align: right;
    }

    &--align-center {
        text-align: center;
    }

    &--size {

        &_2 {
            font-size:  0.8571rem; //12px
        }

        &_3 {
            font-size:  1.071rem; //15px
        }

        &_4 {
            font-size: 1.143rem; //16px
        }

        &_5 {
            font-size: 1.286rem; //18px
        }

        &_6 { // needed for date in blog-post
            font-size:  2rem; //28px
        }
    }
}
