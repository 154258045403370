.#{$namespace}table-results {
    $root: &;
    display: block;
    width: 100%;
    height: auto;
    overflow-x: auto;

    &__table {
        width: 100%;
        display: table;
        border-collapse: collapse;
        overflow-x: auto;
    }

    &__header {
        cursor: pointer;
        @include rem( padding, 8px 0 8px 8px );
        @include rem( border-right, 2px solid $color-white );
        @media #{$medium-up} {
            @include rem( padding, 10px 0 10px 16px );
        }

        &--width {

            &_10 {
                width: 10%;
            }
        }
    }

    &__body {
        background: $color-light;

        #{$root}__row {
            &:nth-child( even ) {
                background: $color-white;
            }

            &:nth-child( odd ) {
                background: $color-light;
            }
        }
    }

    &__row {
        text-align: left;
        vertical-align: middle;

    }

    &__head {
        text-align: left;
        vertical-align: center;
        background: $color-white;
    }

    &__item {
        @include rem( padding, 8px 0 8px 8px );
        @include rem( border-right, 2px solid $color-white );
        text-align: left;
        vertical-align: middle;
        @media #{$medium-up} {
            @include rem( padding, 10px 0 10px 16px );
        }
    }

    &__icon {
        display: block;
        margin: 0 auto;
        @include rem( height, 25px );
        @include rem( width, 25px );

        &--fill {
            &_red {
                fill: $color-dark-red-90;
            }
        }

        &--sm {
            display: none;

            @media #{$medium-up} {
                display: inline-block;
                @include rem( height, 10px );
                @include rem( width, 10px );
                @include rem( margin-left, 8px );
            }
        }
    }

}
