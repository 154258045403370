.#{$namespace}input {
    $root: &;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
    @include rem( padding, 15px 0 );

    @media #{$medium-up} {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    &--bordered {

        &_bottom {
            @include rem( margin-bottom, 30px );
            @include rem( padding, 30px 0 );
            border-bottom: $border;
        }

        &_top-bottom {
            @include rem( margin-top, 15px );
            @include rem( padding, 15px 0 );
            border-top: $border;
            border-bottom: $border;

            @media #{$medium-only} {
                flex-direction: column-reverse;
            }
        }
    }

    &--checkbox {
        width: 100%;
        justify-content: flex-end;
        padding: 0;
        @media #{$medium-up} {
            width: 40%;
            margin-left: 40%;
        }
        @media #{$large-up} {
            margin-left: 30%;
        }
    }

    &--radio {
        width: auto;
        flex-direction: row;
    }


    &__label {
        width: 100%;
        order: 3;

        @media #{$medium-only} {
            width: 40%;
        }
        @media #{$large-up} {
            width: 30%;
        }
        &--reversed {
            width: auto;
            color: $color-grey;
            font-size:  0.8571rem;
        }
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        @include rem( padding, 8px 0 );

        @media #{$medium-up} {
            flex-direction: column;
            width: 45%;
        }

        @media #{$large-up} {
            width: 40%;
        }

        #{$root}__label {
            @include rem( margin-bottom, 4px );
            width: 50%;
            @media #{$medium-only} {
                width: 100%;
            }
        }
    }

    &__icon {
        display: block;
        height: 2.05em;
        width: 2.05em;
        margin: .25em 1em;
        background: $color-light;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        @media #{$xsmall-only}, #{$small-only} {
            left: 60%;
            @include rem( top, -35px );
        }

        &-info {
            position: absolute;
            height: .85em;
            width: .85em;
            margin: .6em;
        }


    }

    &__info {
        display: none;
        text-align: center;
        height: auto;
        border: $border;
        @include rem(padding, 10px );
        position: absolute;
        right: 0;
        @include rem(top, -30px );
        background: $color-white;
        z-index: 2;

        @media #{$medium-up} {
            @include rem(top, -50px );

        }
        @media #{$large-up} {
            @include rem(top, -10px );

        }

        &::before {
            display: block;
            content: '';
            @include rem( width, 14px );
            @include rem( height, 14px );
            position: absolute;
            background-color: $color-white;
            transform: rotate( 45deg );
            z-index: 1;
            @include rem( bottom, -7px );
            @include rem( left, 10px );
            border-right: $border;
            border-bottom: $border;

            @media #{$medium-up} {
                @include rem( left, 50% );
            }

            @media #{$large-up} {
                @include rem( left, -7px );
                @include rem( bottom, 10px );
                border-left: $border;
                border-right: 0 ;
            }

        }
    }


    &__field {
        width: 100%;
        border-radius: 0;
        border: $border;
        font-size: 0.8571rem;
        @include rem(padding, 10px 5px);
        outline: 0;
        color: $color-grey;
        order: 2;

        @media #{$medium-up} {
            width: 45%;
        }
        @media #{$large-up} {
            font-size: 1rem;
            width: 40%;
        }


        &::-ms-clear {
            display: none;
        }

        &::placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            display: none;
        }
        
        &::-moz-placeholder {
            opacity: 1;
            color: $color-darkest;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 1000px $color-white inset;
        }

        &--short {
            @media #{$large-up} {
                width: 20%;
            }
        }

        &--select {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            width: .5px;
            height: 100%;
            padding: 0;
            opacity: 0;
            border: none;

            &--mobile {
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                z-index: 4;
            }
        }

        &--radio {
            width: auto;
            @include rem( margin, 0 8px 0 );
        }

        &--checkbox {
            width: auto;
            @include rem(margin-right, 15px);
            visibility: hidden;
            position: absolute;
            -webkit-appearance: none;

            & + label {
                &:before {
                    @include rem(width, 20px );
                    @include rem(height, 20px );
                    @include rem(margin-right, 10px );
                    content: '';
                    display: inline-block;
                    vertical-align: bottom;
                    background: $color-white;
                    border: $border;
                    border-radius: 0;
                }
            }

            &:checked  + label {
                &:before {
                    border: $border;
                    background: url( 'images/check.png' ) no-repeat center;
                }

            }
        }

        &--textarea {
            @include rem(height, 100px);
        }

        &--no-border {
            border: 0;
        }
    }
}



.parsley {
    &-errors-list {
        order: 1;
        list-style: none;
        margin: 0;
        align-self: center;
        @include rem(padding, 0 15px );

        @media #{$xsmall-only}, #{$small-only}, #{$medium-only} {
            position: absolute;
            @include rem( bottom, -8px );
        }

        @media #{$xsmall-only}, #{$small-only} {
            left: 0;
        }

        @media #{$medium-only} {
            left: 40%;
        }
    }

    &-required, &-type {
        color: $color-dark-red-80;
    }
}

.#{$namespace}html-select {
    $root: &;
    position: relative;
    line-height: 0;
    width: 60%;
    @media #{$medium-up} {
        width: 45%;
    }

    @media #{$large-up} {
        width: 40%;
    }

    &__trigger {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: 100%;
        padding: 0 3em 0 .75em;
        padding-right: 20px !important;
        background-color: $color-white;
        height: 2.55em;
        border: $border;
        text-align: left;
        z-index: 3;
        outline: none;
        overflow: hidden;
        box-sizing: border-box;
        font-size: 1em;

        #{$root}--open &,
        #{$root}.open & {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &--disabled {
            color: $color-grey;
            cursor: not-allowed;
        }

        #{$root}--error & {
            border-color: $border;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:focus {
            //box-shadow: 0 0 5px 0 #3899e2;
        }
    }

    &__trigger-text {
        display: flex;
        height: 100%;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1rem;
        line-height: 1.155em;
        color: $color-grey;

    }

    &__text-content {
        overflow: hidden;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: .125em .313em .125em 0;
    }

    &__trigger-caret {
        position: absolute;
        top: 0;
        right: 0;
        //width: 2.65em;
        width: 2em;
        height: 100%;
        background: $color-light;

        &::before {
            content: '';
            width: 12px;
            height: 100%;
            transform: skew(-20deg);
            background: $color-light;
            display: block;
            left: -6px;
            position: absolute;
        }
    }

    &__trigger-caret-arrow {
        position: absolute;
        width: 10px;
        height: 4px;
        top: 50%;
        right: 50%;
        transform: translate3d( 50%, -50%, 0 );
        transform-origin: 50% 30%;
        transition: transform .25s ease;

        #{$root}--open &,
        #{$root}.open & {
            transform: rotateX( 180deg ) translate3d( 50%, -50%, 0 );
        }

        &:before,
        &:after {
            position: absolute;
            top: 0;
            width: 7px;
            height: 1px;
            content: '';
            background-color: $color-dark-red-80;
        }

        &:before {
            left: 0;
            transform: rotate( 40deg );
            transform-origin: 0 50%;
        }

        &:after {
            right: 0;
            transform: rotate( -40deg );
            transform-origin: 100% 50%;
        }
    }

    &__trigger-subtext {
        position: absolute;
        right: .625em;

        #{$root}__trigger & {
            right: 3.275em;
        }
    }

    &__menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 5;
        display: none;
        width: 100%;
        background-color: $color-white;
        border: $border;
        border-top: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        box-shadow: 0 1px 5px 0 rgba( 100, 100, 100, .1 );
        font-size: 1rem;
        line-height: 1.125rem;
        box-sizing: border-box;

        #{$root}--open &,
        #{$root}.open & {
            display: block;
            opacity: 0;
            transform: translate3d( 0, -10px, 0 );
            transition: transform .25s ease, opacity .32s ease;
        }

        #{$root}--animate & {
            transform: translate3d( 0, 0, 0 ) !important;
            opacity: 1 !important;
        }

    }

    &__menu-list {
        margin: 0;
        padding: 0;
        list-style: none;
        //max-height: 80px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__menu-item {

        &--divider {
            width: 100%;
            height: 1px;
            margin: .313em 0;
            background-color: $color-white;
        }

        &--header {
            padding: .563em .625em .313em;
            font-size: .6875rem;
            text-transform: uppercase;
            color: $color-grey;
        }

        &--hidden {
            display: none;
        }

        &--no-results {
            padding: .563em .625em;
            background-color: $color-white;
        }
        &:before {
            display: none !important;
        }
    }

    &__menu-item-text {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-grey;
    }

    &__menu-link {
        display: block;
        position: relative;
        padding: .563em .625em;
        cursor: pointer;
        background-color: $color-white;
        transition: background-color .15s linear, color .15s linear;

        &:focus {
            outline: none;
            background-color: $color-light;
        }

        &:hover,
        &:hover:focus {
            background-color: $color-light;
            color: $color-dark-red-90;
        }

        #{$root}__menu-item--selected & {
            color: $color-grey;

            &:hover {
                background-color: $color-grey;
                color: $color-dark-red-90;
            }
        }

        #{$root}__menu-item--in-group & {
            padding-left: 1.25em;
        }

        #{$root}__menu-item--disabled & {
            cursor: not-allowed;
            background-color: $color-light;
            color: $color-grey;
            text-decoration: none;

            &:hover {
                background-color: $color-light;
                color: $color-grey;
            }
        }

        #{$root}__menu-item--active & {
            background-color: $color-light;
        }
    }

    &--multi.#{$namespace}html-select--mobile
    {
        #{$root}__trigger {
            display: none;
        }

        .#{$namespace}select--mobile {
            position: static !important;
            opacity: 1 !important;
            border: $border;
            min-height: 2em;
            background-color: $color-light;
        }
    }
}
