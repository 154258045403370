.#{$namespace}alphabetical-list {
    $root: &;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    max-width: $base-content-width;
    height: auto;
    background: $color-white;
    @include rem( padding, 15px 30px );

    &__letter-wrapper {
        display: flex;
        justify-content: center;
        cursor: pointer;
        transform: skew( -20deg );
        @include rem( padding, 0 4px );
        min-width: 10.6%;
        margin: 0 auto;
        @media #{$medium-up} {
            min-width: 7.5%;
        }

        @media #{$large-up} {
            min-width: 0;
            padding: 0;
        }


        &:hover, &:focus {
            background: $color-dark-red-80;

            #{$root}__letter {
                color: $color-white;
            }
        }

        &--active {
            background: $color-dark-red-80;

            #{$root}__letter {
                color: $color-white;
            }
        }
    }

    &__letter {
        @include rem( padding, 10px 5px );
        transform: skew( 20deg );
        margin: 0;
        @media #{$large-up} {
            @include rem( padding, 10px );

        }
    }
}
