.#{$namespace}link {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    outline: none;

    &--red {
        color: $color-dark-red-80;
    }

    &--light-grey {
        color: $color-light-grey;
    }

    &--hoverable {
        transition: color .2s ease;
        &:hover {
            color: $color-dark-red-90;
        }
    }

    &--button {
        border: 1px solid $color-dark-red-80;
        background: $color-dark-red-80;
        color: $color-white;
        @include rem( padding, 10px 15px );

    }

    &__icon {
        @include rem( height, 30px );
        @include rem( width, 30px );
        margin: 0;

        &-sm {
            @include rem( height, 20px );
            @include rem( width, 20px );
            margin: 0;
        }

        &-lg {
            @include rem( height, 30px );
            @include rem( width, 40px );
            margin: 0;
        }

        &--width {

            &_auto {
                max-width: 100%;
                width: auto;
            }
        }
    }
}
