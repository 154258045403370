.#{$namespace}city-map {
    display: block;
    position: relative;
    width: 100%;
    @include rem( height, 415px );
    @include rem( margin-bottom, 8px );

    &--small {
        @include rem( max-height, 215px );
    }

    &__container {
        width: 100%;
        height: 100%;
    }

    &__full-icon {
        width: 100%;
        height: 100%;
        @include rem( padding, 4px );
    }

    &__item-wrapper {
        @include rem( width, 30px );
        @include rem( height, 30px );
        @include rem( margin, 5px 10px );
        border: $border;
        background: $color-white;
        opacity: .9;
        display: flex;
        align-items: center;

        &--controls {
            justify-content: center;
        }

        &--full {
            position: absolute;
            @include rem( bottom, 15px );
            right: 0;
        }
    }

    &__wrapper {
        display: block;
        @include rem( padding, 15px );
    }

    &__zoom {
        cursor: pointer;
        @include rem( width, 15px );
        @include rem( height, 15px );
    }

    &__full {

        &-text {
            text-transform: uppercase;
            font-size: 1.1rem;
        }
    }

    &__search {
        box-shadow: 0 1px 4px 0 rgba( 34, 34, 34, .31 );
        background: $color-white;
        @include rem( width, 280px );
        @include rem( margin, 4px );
        height: auto;
        position: absolute;
        right: 2%;
        top: 6%;
        @media #{$xlarge-up} {
            width: auto;
        }

        &--initial {
            position: initial;
            width: 100%;
            margin: 0;
            top: initial;
            right: initial;
        }

        &-row {
            display: flex;
            flex-direction: column;
            @include rem(padding, 15px);

            &--dark-bottom {
                @include rem( padding-right, 20px );
                background: $color-darkest;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
            }
        }

        &--bottom {
            width: 100%;
            top: 100%;
            right: 0;
            left: 0;
            margin: 0;
        }
    }

    &__form {
        @include rem( padding, 0 );

        &::-ms-clear {
            display: none;
        }

        &::placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            font-size: .8571rem;
        }

        &-element {
            font-size: .6571rem;
            margin: 0;
            -webkit-appearance: none;
            border-radius: 0;
            @media #{$medium-up} {
                font-size:  .8571rem;
            }

            &--label {
                text-transform: uppercase;
                color: $color-grey;
            }

            &--input {
                width: calc( 100% - 15px );
                @include rem( padding, 10px 5px );
                outline: 0;
                color: $color-darkest;
                border: $border;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    transition: background 5000s ease-in-out 0s;
                    -webkit-box-shadow: 0 0 0 1000px $color-white inset;
                }

            }
        }
    }

    &__paragraph {
        margin: 0;

    }
}
