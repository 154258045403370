.#{$namespace}toggle-box {
    display: block;
    background: $color-white;
    width: 100%;
    height: 100%;

    &__icon {
        @include rem( width, 40px );
        @include rem( height, 30px );
        fill: $color-grey;
        transition: transform .3s;

        &--active {
            fill: $color-dark-red-90;
            transform: rotate( 180deg );
        }

        &-small {
            @include rem( width, 10px );
            @include rem( height, 10px );
            @include rem( margin-left, 5px );
            display: inline;
            fill: $color-dark-red-80;
            transition: fill .2s;

            &:hover {
                fill: $color-dark-red-90;
            }
        }
    }

    &__text {
        margin: 0;
        width: 100%;
        @media #{$medium-up} {
            width: 70%;
        }
        @media #{$large-up} {
            width: 80%;
        }

        &--headline {
            font-weight: 500;
        }
    }

    &__wrapper {
        @include rem( padding, 15px 30px );
    }

    &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--hidden {
            display: flex;
            @include rem( padding-top, 15px );
            flex-direction: column;
            align-items: flex-start;
            @media #{$medium-up} {
                flex-direction: row;
                align-items: center;
            }
        }

        &--clickable {
            cursor: pointer;
        }
    }

    &__image-wrapper {
        display: block;
        width: 40%;
        height: auto;
        @include rem(padding-right, 30px);
        @include rem(padding-bottom, 15px);
        @media #{$medium-up} {
            width: 30%;
            padding-bottom: 0;
        }
        @media #{$large-up} {
            width: 20%;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
    }
}
