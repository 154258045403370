.#{$namespace}teaser {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba( 0, 0, 0, .3 );
    text-decoration: none;
    z-index: 13;

    top: calc( 50% - 25px );
    left: calc( 50% - 25px );
    @include rem( height, 60px );
    @include rem( width, 60px );

    &--initial {
        position: static;
    }

    &--player-sm {

        @media #{$medium-up} {
            @include rem( width, 70px );
            @include rem( height, 70px );
            top: calc( 50% - 30px );
            left: calc( 50% - 30px );
        }
    }

    &--player-red {
        @include rem( width, 100px );
        @include rem( height, 100px );
        top: calc( 50% - 50px );
        left: calc( 50% - 50px );
    }

    &--player {

        @media #{$medium-up} {
            top: calc( 50% - 40px );
            left: calc( 50% - 40px );
            @include rem( height, 80px );
            @include rem( width, 80px );
        }

    }

    &__image {
        @include rem( margin, 5px );
        opacity: .7;
        fill: $color-white;
        transition: opacity .3s;

        &:hover {
            opacity: 1;
        }

        &--player {
            @include rem( width, 50px );
            @include rem( height, 50px );
            @media #{$medium-up} {
                @include rem( width, 70px );
                @include rem( height, 70px );
            }
        }

        &--player-sm {
            @include rem( width, 50px );
            @include rem( height, 50px );
            @media #{$medium-up} {
                @include rem( width, 60px );
                @include rem( height, 60px );
            }
        }

        &--player-red {
            opacity: .85;
            @include rem( margin, 25px );
            @include rem( width, 50px );
            @include rem( height, 50px );
        }
    }

    &--hero-hidden {
        opacity: 0;
    }
}
