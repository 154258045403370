.#{$namespace}loader{
    $root: &;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, .6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        transform: skew( -40deg );
    }

    &__animatable {
        position: absolute;
        top: calc( 50vh - 16px );
        left: calc( 50vw - 30px );
        z-index: 101;
        @include rem( width, 50px );
    }

    &__skew {
        display: inline-block;
        @include rem( height, 12px );
        @include rem( margin, 0 4px 4px 0 );
        width: calc( 26% - 4px );
        opacity: 0;
        &--sm {
            animation: pulse 2s infinite linear;

        }

        &--md {
            animation: pulse2 2s infinite linear;

            width: calc( 30% - 4px );
        }

        &--lg {

            animation: pulse3 2s infinite linear;

            width: calc( 44% - 4px );
        }

        &--black {
            background: $color-darkest;
        }

        &--red{
            background: $color-dark-red-80;
        }
    }

    @keyframes pulse {
        0% {
            opacity: .3;
        }
        33% {
            opacity: .7;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes pulse2 {
        0% {
            opacity: .1;
        }
        33% {
            opacity: .45;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes pulse3 {
        0% {
            opacity: 0;
        }
        33% {
            opacity: .3;
        }
        100% {
            opacity: 1;
        }
    }
}
