.#{$namespace}button {
    $root: &;
    display: block;
    transform: skew( -20deg );
    @include rem( padding, 0 15px );
    @include rem( width, 200px );
    transition: background .3s, color .3s;
    outline: none;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    line-height: 1;
    @include rem( height, 34px );
    @media #{$large-up} {
        @include rem( padding, 0 30px );
    }

    &:hover {
        #{$root}__text {
            color: $color-dark-red-90;
        }

        #{$root}__icon {
            fill: $color-dark-red-90;
        }
    }

    &--padding {
        &_15 {
             @include rem( padding, 0 15px );
        }
    }

    &--big {
        @include rem( height, 30px );
        @include rem( line-height, 30px );
        @media #{$medium-up} {
            @include rem( height, 40px );
        }
    }

    &--width {

        &_48 {
            width: 48%;
        }

        &_100 {
            width: 100%;
        }

        &_auto {
            width: auto;
            letter-spacing: -.29px;
            @media #{$medium-up} {
                letter-spacing: normal;
            }
        }
    }

    &--mod-left {
        transform: skew( 0deg );

        #{$root}__wrapper {
            transform: skew( 0deg );
        }

        &::after {
            content: '';
            position: absolute;
            background: $color-dark-red-80;
            top: 0;
            @include rem( right, -18px );
            bottom: 0;
            @include rem( width, 38px );
            transform: skew(-20deg);
            transition: background .3s;
            z-index: 1;
        }

        &:hover::after {
            background: $color-dark-red-90;
        }

        #{$root}__icon {
            @include rem( margin-right, -15px );
        }

    }

    &--hidden-mobile {
        display: none;
        @media #{$medium-up} {
            display: block;
        }
    }

    &__wrapper {
        height: 100%;
        display: flex;
        justify-content: space-between;
        transform: skew( 20deg );
        position: relative;
        z-index: 2;
    }

    &--white {
        border: 1px solid $color-light-grey;
        background: $color-white;
    }

    &--red {
        background: $color-dark-red-80;
        border: 0;

        &:hover {
            background: $color-dark-red-90;

            #{$root}__text {
                color: $color-white;
            }

            #{$root}__icon {
                fill: $color-white;
            }
        }
    }

    &--transparent {
        background: none;
        border: $border;
    }

    &__link {
        text-decoration: none;
        outline: none;
    }

    &__icon {
        display: block;
        align-self: center;
        @include rem( width, 10px );
        @include rem( height, 10px );
        @include rem( margin-left, 5px );
        fill: $color-dark-red-80;

        &--white {
            fill: $color-white;
        }

        &-file {
            @include rem( width, 20px );
            @include rem( height, 20px );
            @include rem( margin-right, 5px );
        }

    }

    &__text {
        text-transform: uppercase;
        align-self: center;
        margin: 0 auto;
        transition: color .3s;
        font-weight: 500;

        &--white {
            color: $color-white;
        }
    }

}
