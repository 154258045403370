.#{$namespace}search {
    $root: &;
    display: block;
    width: auto;
    height: auto;
    background: $color-white;
    position: relative;
    z-index: 3;

    &--hidden {
        display: none;
        width: 100%;
        @include rem( height, 80px );
        position: absolute;
        @include rem( top, 80px );
        transition: top .25s;
        right: 0;
        left: 0;
        @media #{$large-up} {
            top: 0;
            @include rem( padding, 0 85px );

        }
    }

    &__wrapper {
        display: flex;
        width: 100%;
        max-width: $base-content-width;
        margin: 0 auto;
        height: 100%;
    }

    &--visible {
        display: block;
    }

    &__form {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        justify-content: flex-start;

        &--hidden {
            @include rem( padding, 20px );
            @include rem( margin-right, 20px );
            @media #{$large-up} {
                width: 75%;
            }

            &::after {
                @media #{$large-up} {
                    content: '';
                    background: $color-grey;
                    transform: rotate( 40deg );
                    display: block;
                    @include rem( height, 105px );
                    @include rem( width, 1px );
                    position: absolute;
                    @include rem( top, -13px );
                    right: 0;
                }
            }
        }


        .sg-html-select {
            @include rem( margin, 8px 15px 8px 0 );
            width: 100%;
            @media #{$medium-up} {
                width: calc( 50% - 16px );
            }

            @media #{$large-up} {
                width: calc( 25% - 15px );
                @include rem( margin-right, 15px );
            }

            &__trigger {
                @include rem( height, 30px );

                @media #{$medium-up} {
                    @include rem( height, 40px );
                }

                &-text {
                    color: rgb( 117, 117, 117 );
                }
            }
        }


        #{$root}__button {
            @include rem( margin, 8px auto 0 );

            @media #{$medium-up} {
                margin: 0 auto;
                @include rem( margin, 0 30px 0 auto );
            }
        }
    }

    &__logo {
        display: none;
        @media #{$large-up} {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 25%;
        }

        &-image {
            @media #{$large-up} {
                align-self: flex-end;
            }
        }
    }

    &__searchinput {
        width: auto;
        max-width: 100%;
        border: 0;
        outline: none;
        font-weight: 400;
        @include rem( padding, 0 8px );
        @include rem( height, 30px );
        border: $border;
        font-size: 1rem;
        color: $color-grey;
        @include rem( margin, 8px 15px 8px 0 );

        @media #{$medium-up} {
            @include rem( height, 40px );
            //max-width: 50%;
            //width: calc( 50% - 16px );
        }

        @media #{$large-up} {
            margin: 0;
            //width: calc( 25% - 15px );
            //@include rem( margin-right, 15px );
        }

        &--hidden {
            height: 100%;
            @include rem( padding-left, 10px );
            @include rem( margin-right, 0 );
            border: 0;
        }

        &::-ms-clear {
            display: none;
        }

        &::placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &::-webkit-input-placeholder {
            opacity: 1;
            font-size: 1rem;
            color: $color-grey;
        }

        &::-moz-placeholder {
            opacity: 1;
            font-size: 1rem;
            color: $color-grey;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 1000px $color-white inset;
        }
    }

    &__icon {
        align-self: center;
        fill: $color-grey;
        @include rem(width, 20px);
        @include rem(height, 20px);
    }

    &__close {
        position: absolute;
        top: calc( 50% - 15px );
        @include rem( right, 10px );
        @include rem( width, 30px );
        @include rem( height, 30px );
        @media #{$large-up} {
            @include rem( right, 40px );
        }
    }

    &__closeicon {
        background: $color-grey;
        transform: rotate( -45deg );
        @include rem( height, 30px );
        display: block;
        position: relative;
        @include rem( width, 1px );

        &::after {
            background: $color-grey;
            content: '';
            @include rem( height, 1px );
            @include rem( width, 30px );
            @include rem( left, -14px );
            @include rem( top, 14px );
            position: absolute;
        }
    }

    &__button {

        @media #{$medium-up} {
            @include rem( width, 150px );
        }
    }
}

.#{$namespace}search-results {
    width: 100%;
    max-width: $base-content-width;
    margin: 0 auto;
    @include rem( margin-top, 24px );
    @media #{$medium-up} {
        margin-top: 0;
    }

    .page-navigation {
        display: none;
    }
}